import React, { useEffect, useState, useRef } from 'react';
import { ELSI_WEBSOCKET_URL, errorCallback, makeUrl, succesCallbackEmpty } from '../const/const';
import { getFetchRequest, MAKE_ORDER_REQUEST, postFetchRequest } from '../store/requests';
import { ErrorHandler } from './sharedComponents/ErrorHandler';
import ModalItem from './Modal';
import { ChevronIcon } from '../appearance/icons/chevron_icon';
import { handleTransformDate } from './sharedComponents/helper';
import { PricesList } from './sharedComponents/ProductItem';

const modalContent = 'После формирования счета ваш заказ переходит в статус "В работе", в него нельзя будет добавить новые продукты.';

const LIST_OF_STATUSES = {
    CREATED: 'Создан',
    PLACED: 'Размещен',
    CANCELLED: 'Отменен',
    PAID: 'Оплачен',
    DONE: 'Завершен',
};

const handleFindActivePrice = (ordersList, orderIndex, index, value) => {
    let currentData = {minQty: 0};
    let allPricesArray = [];
    if(ordersList[orderIndex].products[index].pricesList) {
        allPricesArray = [...allPricesArray, ...ordersList[orderIndex].products[index].pricesList];
    }
    allPricesArray.map(it => it.minQty<=value&&it.minQty>currentData.minQty? currentData = it:null);
    return currentData;
};

const OrdersTable = ({ ordersList }) => {
    return (
        <table className="table orders__table">
            <thead>
                <tr className="table-header">
                    <th className="table-header__item">Номер заказа</th>
                    <th className="table-header__item">Общая стоимость</th>
                    <th className="table-header__item">Создан</th>
                    <th className="table-header__item">Оформлен</th>
                    <th className="table-header__item">Оплачен</th>
                    <th className="table-header__item">В комплектации</th>
                    <th className="table-header__item">Поступил на склад</th>
                    <th className="table-header__item">Готов к выдаче</th>
                    <th className="table-header__item">Отправлен</th>
                    <th className="table-header__item">Получен</th>
                </tr>
            </thead>
            <tbody>
                {ordersList.map(it => {
                    return (
                        <tr key={it.id} className="table-row">
                            <td className={`table__item ${it.id?'table__item_active':''}`}>{it.orderNumber}</td>
                            <td className={`table__item ${it.totalCost?'table__item_active':''}`}>{it.totalCost}</td>
                            <td className={`table__item table__item-date ${it.dateCreation?'table__item_active':''}`}>{handleTransformDate(it.dateCreation)}</td>
                            <td className={`table__item table__item-date ${it.datePlaced?'table__item_active':''}`}>{handleTransformDate(it.datePlaced)}</td>                            
                            <td className={`table__item table__item-date ${it.datePaid?'table__item_active':''}`}>{handleTransformDate(it.datePaid)}</td>
                            <td className={`table__item `}></td>
                            <td className={`table__item `}></td>
                            <td className={`table__item `}></td>
                            <td className={`table__item `}></td>
                            <td className={`table__item table__item-date ${it.dateDone?'table__item_active':''}`}>{handleTransformDate(it.dateDone)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const makeWsRequests = (ws, array) => {
    for(let i=0; i<array.length; i++) {
        for(let j=0; j<array[i].products.length; j++) {
            ws.current.send(JSON.stringify({
                event: "/product/price",
                productId: array[i].products[j].productId,
                productPartNumber: array[i].products[j].productName,
            }));
        }
    }  
};

const OrdersPage = ({ isCollapsed, mainPage=null }) => {
    const [ordersList, setOrdersList] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState('');
    const [productsListIsOpen, setProductsListIsOpen] = useState(false);
    const [activePricesIsChanged, setActivePricesIsChanged] = useState(false);
    const ws = useRef();

    const gettingData = (array) => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            try {
                const message = JSON.parse(e.data);
                if(message && message.length) {
                    array.forEach(it => {
                        for(let i = 0; i<it.products.length; i++) {
                            if(it.products[i].productId === message[0].productId) {
                                it.products[i].pricesList = message;
                            }
                        }
                    });
                    setOrdersList([...array]);
                }
            } catch (err) {
                console.log(err);
            }
        };
    };
    
    const getProductPriceData = (array) => {
        if(!ws.current) {
            ws.current = new WebSocket(`${ELSI_WEBSOCKET_URL}/ws/v1?token=${localStorage.getItem('token')}`);
            ws.current.onopen = () => {
                makeWsRequests(ws, array);
            };
        } else {
            makeWsRequests(ws, array);
        }
        gettingData(array);

    };

    const succesGetOrderCallback = (result) => {
        if(result.length) {
            if(mainPage) {
                setOrdersList(result.slice(0, 6));
            } else {
                setOrdersList(result);
                getProductPriceData(result);
            }
        }
    };

    useEffect(() => {
        getFetchRequest(makeUrl([MAKE_ORDER_REQUEST]), succesGetOrderCallback, errorCallback);
        return () => {
            ws.current&&ws.current.close();
            ws.current = null;
        };
    },[]);

    const successGetDataCallback = async (response, orderId) => {
        const blob = await response.blob();
        const newBlob = new Blob([blob]);
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `${orderId}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setCurrentOrderId('');
        setModalIsOpen(false);
        getFetchRequest(makeUrl([MAKE_ORDER_REQUEST]), succesGetOrderCallback, errorCallback);
    };

    const handleGetOrderData = () => {
        getFetchRequest(makeUrl([MAKE_ORDER_REQUEST, currentOrderId, '/invoice/']), (response) => successGetDataCallback(response, currentOrderId), errorCallback, true);
    };

    const handleOpenModal = (e, id) => {
        e.stopPropagation();
        setCurrentOrderId(id);
        setModalIsOpen(true);
    };

    const handleCloseModal = (e) => {
        e.stopPropagation();
        setModalIsOpen(false);
    };

    const handleChangeQuantity = (step, index, orderIndex, value=null) => {
        if( ordersList[orderIndex].products[index].productCount === 0 && step === -1) {
            return;
        }
        const coppiedData = [...ordersList];
        let newQuantityValue;
        if(step !== 0 ) {
            newQuantityValue = ordersList[orderIndex].products[index].productCount+step;
        } else {
            newQuantityValue = value;
        }
        coppiedData[orderIndex].products[index].productCount = newQuantityValue;
        const data = {
            stockId: coppiedData[orderIndex].products[index].stockId,
            productCount: newQuantityValue,
        };
        postFetchRequest(makeUrl([MAKE_ORDER_REQUEST, coppiedData[orderIndex].id, '/product/', ordersList[orderIndex].products[index].productId, '/']), 
            data, succesCallbackEmpty, errorCallback, true);
        setOrdersList([...coppiedData]);
        setActivePricesIsChanged(prev => !prev);
    };

    try {
        if (mainPage) {
            return <OrdersTable ordersList={ordersList} />;
        }
        return (
            <section className={`workspace orders-page ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'}`}
                onClick={(e) => handleCloseModal(e)}>
                {modalIsOpen ? <ModalItem handleClose={() => setModalIsOpen(false)} handleLoad={handleGetOrderData} content={modalContent} /> : ''}
                {ordersList.length ?
                    <ul className="orders__list">
                        {ordersList.map((it, orderIndex) => (
                            <li className="orders__item" key={it.id}>
                                <article className="orders__info">
                                    <h3 className="orders__subtitle">Номер заказа</h3>
                                    <p className={`orders__description ${it.id ? 'orders__description_active' : ''}`}>{it.orderNumber}</p>
                                    <h3 className="orders__subtitle">Дата создания</h3>
                                    <p className={`orders__description ${it.dateCreation ? 'orders__description_active' : ''}`}>
                                        {handleTransformDate(it.dateCreation)}
                                    </p>
                                    <h3 className="orders__subtitle">Дата отмены</h3>
                                    <p className={`orders__description ${it.dateCancel ? 'orders__description_active' : ''}`}>
                                        {it.dateCancel ? handleTransformDate(it.dateCancel) : '-'}
                                    </p>
                                    <h3 className="orders__subtitle">Статус</h3>
                                    <p className={`orders__description ${it.status ? 'orders__description_active' : ''}`}>{LIST_OF_STATUSES[it.status]}</p>
                                    <h2 className="orders__total">Общая стоимость: {it.totalCost} {it.currency === 'USD' ? '$' : '₽'}</h2>
                                    <button className="button orders-page__button" onClick={(e) => handleOpenModal(e, it.id)}>Скачать счет</button>
                                </article>
                                <article className={`orders__products ${!productsListIsOpen ? 'orders__products_collapsed' : ''}`}>
                                    {it.products && it.products.length ?
                                        <>
                                            <table className="table orders-page__table ">
                                                <thead>
                                                    <tr className="table-header">
                                                        <th className="table-header__item orders__table-header">Модель</th>
                                                        <th className="table-header__item orders__table-header">Производитель</th>
                                                        <th className="table-header__item orders__table-header">Наличие</th>
                                                        <th className="table-header__item orders__table-header">Цена</th>
                                                        <th className="table-header__item orders__table-header">Количество</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {it.products.map((product, index) => {
                                                        return (
                                                            <tr key={it.id + index} className='table__item-product'>
                                                                <td className="orders__table-item">{product.productName}</td>
                                                                <td className="orders__table-item">{handleFindActivePrice(ordersList, orderIndex, index, product.productCount).manufacturer}</td>
                                                                <td className="orders__table-item">{handleFindActivePrice(ordersList, orderIndex, index, product.productCount).stockNumber} шт.</td>
                                                                <td className="orders__table-item">
                                                                    {product.pricesList && product.pricesList.length ?
                                                                        <PricesList pricesArray={product.pricesList} 
                                                                            activePricesIsChanged={activePricesIsChanged} 
                                                                            activePrice={handleFindActivePrice(ordersList, orderIndex, index, product.productCount)}/>
                                                                        :
                                                                        null
                                                                    }
                                                                </td>
                                                                <td className="orders__table-item product-price__table-item_height">
                                                                    <div className="product-price__table-block">
                                                                        <span className="product-price__table-button product-price__table-button_left"
                                                                            onClick={() => handleChangeQuantity(-1, index, orderIndex)}>-</span>
                                                                        <input className="product-price__table-quantity" value={product.productCount}
                                                                            onChange={(e) => handleChangeQuantity(0, index, orderIndex, +e.target.value)} />
                                                                        <span className="product-price__table-button" onClick={() => handleChangeQuantity(1, index, orderIndex)}>+</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            {it.products.length > 3 ?
                                                <p className="orders__table-subtitle" onClick={() => setProductsListIsOpen(!productsListIsOpen)}>
                                                    {!productsListIsOpen ? 'Развернуть' : 'Свернуть'}
                                                    <ChevronIcon nameOfClass={`product-table__icon ${productsListIsOpen ? 'product-table__icon_collapsed' : ''}`} />
                                                </p> : ''}
                                        </> : null}
                                </article>
                            </li>
                        ))}
                    </ul>
                    :
                    <div className='orders-page__empty'>Ничего не найдено</div>}
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default OrdersPage;
