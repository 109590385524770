import React, { useState, useEffect } from 'react';
import { ELSI_API_URL, errorCallback, makeUrl, succesCallbackEmpty } from '../../const/const';
import { getFetchRequest, MAKE_USER_REQUEST, postFetchRequest } from '../../store/requests';
import CustomSelect from '../CustomSelect';

const getCustomer = (userId) => {
    const result = `${ELSI_API_URL}/api/v1/user/${userId}/`;
    return result;
};

const TabProfile = ({userId}) => {
    const [profileName, setProfileName] = useState('');
    const [profilePhone, setProfilePhone] = useState('');
    const [profileEmail, setProfileEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState({type:'', message:''});
    const [customerRole, setCustomerRole] = useState('');

    const succesCallback = (response) => {
        setProfileName(response.name);
        setProfilePhone(response.phone);
        setProfileEmail(response.email);
    };

    useEffect(()=> {
        if(userId) {
            getFetchRequest(getCustomer(userId), succesCallback, errorCallback);
        }
    }, [userId]);

    const handleCheckPasswords = (e) => {
        setRepeatPassword(e.target.value);
        if(e.target.value === password) {
            setError({type:'', message:''});
        } else {
            setError({type: 'password', message: 'Пароли не совпадают'});
        }
    };

    const successUpdateData = () => {
        setPassword('');
        setRepeatPassword('');
    };

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if(userId) {
            const userData = {
                email: profileEmail,
                phone: profilePhone,
                name: profileName,
                customerRole,
            };
            password?userData.password = password:null;
            postFetchRequest(makeUrl([MAKE_USER_REQUEST, userId, '/']), userData, successUpdateData, errorCallback, true);
        }
    };

    return (
        <>
            <form className="profile-form">
                <label name="fio" className="profile-form__item">ФИО
                    <input className="profile-form__input" value={profileName || ''} onChange={(e) => setProfileName(e.target.value)}/>
                </label>
                <label name="email" className="profile-form__item">Электронная почта
                    <input className="profile-form__input" value={profileEmail || ''} onChange={(e) => setProfileEmail(e.target.value)}/>
                </label>
                <label name="phone" className="profile-form__item">Контактный телефон
                    <input className="profile-form__input" value={profilePhone || ''} onChange={(e) => setProfilePhone(e.target.value)}/>
                </label>
                <label name="password" className="profile-form__item">Пароль
                    <input type='password' className="profile-form__input" value={password  || ''} onChange={(e) => setPassword(e.target.value)}/>
                </label>
                <label name="repeat-password" className="profile-form__item">Повторить пароль
                    <input type='password' className="profile-form__input" value={repeatPassword  || ''} onChange={(e) => handleCheckPasswords(e)}/>
                    {error.type==='password'? <p className='alert'>{error.message}</p>: ''}
                </label>
                <CustomSelect customerRole={customerRole} setCustomerRole={setCustomerRole}/>
                <button onClick={handleSubmitForm} className="button__save">Сохранить</button>
            </form>
        </>
    );
};

export default TabProfile;