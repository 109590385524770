import { ELSI_WEBSOCKET_URL } from "../../const/const";

const preparePartNumberToCompare = (partNumber) => {
    return partNumber.trim().replace(/[^\w\s]/gi, '');
};

export const ABSENCE_PRICES_MESSAGE = 'price-absence';

const createCorePricesList = (pricesArray, productId, partNumber, currentPricesList) => {
    pricesArray.filter(it => it.productId === productId && 
        preparePartNumberToCompare(it.productPartNumber) === preparePartNumberToCompare(partNumber)).forEach(price => {
        const corePriceIndex = currentPricesList.findIndex(it => (
            it.manufacturer === price.manufacturer && it.distributor === price.distributor && it.packer === price.packer
        ));
        if(corePriceIndex === -1) {
            currentPricesList.push({
                partNumber: price.productPartNumber,
                manufacturer: price.manufacturer,
                distributor: price.distributor,
                packer: price.packer,
                packerId: price.packerId,
                source: price.source,
                sourceId: price.sourceId,
                stockNumber: price.stockNumber,
                quantities: [
                    {
                        id: price.priceId,
                        minQty: price.minQty,
                        priceValue: price.priceValue,
                    },
                ],
            });
        } else {
            currentPricesList[corePriceIndex].quantities.push({
                id: price.priceId,
                minQty: price.minQty,
                priceValue: price.priceValue,
            });
        }
    });
    return currentPricesList;
};

const checkConnection = (ws) => {
    return !!ws;
};

const checkWSIsOpenState = (ws) => {
    return ws.readyState === WebSocket.OPEN;
}; 

const sendWSMessage = (ws, productId, partNumber) => {
    ws.send(JSON.stringify({
        event: "/product/price",
        productId: productId,
        productPartNumber: partNumber,
    }));  
};

class priceAsyncService {
    coreObservers = [];
    ws = null;
    interval =  null;
    corePrices = [];
    productId = '';
    partNumber = '';
    isAbsence = false;

    constructor (productId, partNumber) {
        this.productId = productId;
        this.partNumber = partNumber;
    }
    
    createWSConnection() {
        if(!checkConnection(this.ws)) {
            this.ws = new WebSocket(`${ELSI_WEBSOCKET_URL}/ws/v1?token=${localStorage.getItem('token')}`);
            this.ws.onopen = () => {
                sendWSMessage(this.ws, this.productId, this.partNumber);
                // const uuidv4 = 'c8cdf813-3fab-414c-a845-96b1466c1d6e';  
                // this.interval = setInterval(function() {
                //     if(this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                //         this.ws.send(JSON.stringify({
                //             event: "ping",
                //             connectionId: uuidv4,
                //         }));   
                //     }  else {
                //         clearInterval(this.interval);
                //     }
                // }, 1000);
            };
        } else {
            checkWSIsOpenState(this.ws)&&sendWSMessage(this.ws, this.productId, this.partNumber);
        }
        this.ws.onmessage = e => {
            try {
                const message = JSON.parse(e.data);
                this.corePrices = createCorePricesList(message, this.productId, this.partNumber, this.corePrices);
                this.notifyPriceObservers();
            } catch {
                if(e.data === ABSENCE_PRICES_MESSAGE) {
                    this.isAbsence = true;
                    this.notifyPriceObservers();
                }
            }
        };
    }

    getPrices() {
        return this.corePrices;
    }

    registerPriceObserver(func) {
        this.coreObservers.push(func);
    }

    unRegisterPriceObserver(func) {
        try {
            this.coreObservers = this.coreObservers.filter(fn => fn !== func);
        } catch(err) {
            console.log(err);
        }
    }

    cleanObservers() {
        this.coreObservers = [];
    }

    notifyPriceObservers() {
        this.coreObservers.map(func => this.isAbsence ? func(ABSENCE_PRICES_MESSAGE) : func(this.getPrices()));
    }
};

let priceAsyncServiceItem = new priceAsyncService();

export const handleCreateNewPriceAsyncService = (productId, partNumber) => {
    if(priceAsyncServiceItem.productId !== productId || priceAsyncServiceItem.partNumber !== partNumber) {
        priceAsyncServiceItem = new priceAsyncService(productId, partNumber);
        priceAsyncServiceItem.createWSConnection();
    }
};

export const registerObserver = (func) => {
    priceAsyncServiceItem.registerPriceObserver(func);
};

export const unRegisterObserver = (func) => {
    priceAsyncServiceItem.unRegisterPriceObserver(func);
};

export const clearPricesAsyncService = () => {
    priceAsyncServiceItem = new priceAsyncService();
};
