export const IS_LIMIT_REACHED_KEY_ERROR = 'isLimitReached'; 
export const IS_NOT_ENOUGH_KEY_ERROR = 'isNotEnough';

export const sortByQuantity = (a, b) => a.minQty - b.minQty;

export const createPriceUniqueKey = (price) => price.distributor + price.manufacturer + price.packerId + price.productId + price.sourceId;

export const showPricesWithSpaces = (priceValue) => {
    const parts = priceValue.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
};

export const cartManager = {
    observersList: [],
    currentPrices: {},
    registerCartObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterCartObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
            this.currentPrices = {};
        } catch(err) {
            console.log(err);
        }
    },

    notifyCartObservers() {
        this.observersList.forEach(observer => observer(Object.values(this.currentPrices)));
    },
    
    setProductsCount(price, count) {
        price.count = count;
        if(!this.isLimitReached(price) && this.isCountEnough(price)) {
            this.currentPrices[createPriceUniqueKey(price)] = price;
            this.notifyCartObservers();
            return {};
        } else {
            const error = {error: this.isLimitReached(price) ? IS_LIMIT_REACHED_KEY_ERROR : IS_NOT_ENOUGH_KEY_ERROR};
            price.count = 0;
            this.currentPrices[createPriceUniqueKey(price)] = price;
            return error;
        }
    },

    isLimitReached(price) {
        return price.stockNumber < price.count;
    },

    isCountEnough(price) {
        let smallestValue;
        let count = 0;
        if(price) {
            smallestValue = price.quantities.sort(sortByQuantity)[0].minQty;
            count = price.count;
        } else {
            let result;
            for(let price of Object.values(this.currentPrices)) {
                result = result && price.quantities.sort(sortByQuantity)[0].minQty <= price.count;
            }
            smallestValue = result === false ? 2 : 1;
            count = 1;
        }
        return (price && price.count === 0) || smallestValue <= count;
    },

    getCurrentPrices() {
        return this.currentPrices;
    },
    
    clearService() {
        this.currentPrices = {};
        this.notifyCartObservers();
    },
};
