import React, { useState, useEffect } from 'react';
import { errorCallback, makeUrl, SETUP_TAB_CUSTOMER, SETUP_TAB_DELIVERY, SETUP_TAB_NOTIFICATIONS, SETUP_TAB_PROFILE, SETUP_TAB_USERS } from '../const/const';
import { getFetchRequest, GET_CURRENT_USER } from '../store/requests';
import TabCustomer from './setupTab/TabCustomer';
import TabProfile from './setupTab/TabProfile';

const SetupPage = ({ isCollapsed, defaultCustomer, setDefaultCustomer }) => {
    const [activeSetupTab, setActiveSetupTab] = useState(SETUP_TAB_PROFILE);
    const [userId, setUserId] = useState('');

    const succesCallback = (response) => {
        setUserId(response.id);
    };

    useEffect(()=> {
        getFetchRequest(makeUrl([GET_CURRENT_USER]), succesCallback, errorCallback);
    },[]);

    return (
        <section className={`workspace workspace_align ${isCollapsed===null ? '': isCollapsed? 'workspace_collapsed' : 'workspace_full-width'}`}>
            <div className="setup-tab">
                <ul className="tab-title__list">
                    <li className={`tab-title__item ${activeSetupTab===SETUP_TAB_PROFILE? 'tab-title__item_active':''} navigation-link`} onClick={() => setActiveSetupTab(SETUP_TAB_PROFILE)}>Профиль</li>
                    <li className={`tab-title__item ${activeSetupTab===SETUP_TAB_CUSTOMER? 'tab-title__item_active':''} navigation-link`} onClick={() => setActiveSetupTab(SETUP_TAB_CUSTOMER)}>Покупатель</li>
                    <li className={`tab-title__item ${activeSetupTab===SETUP_TAB_USERS? 'tab-title__item_active':''} navigation-link`}>Пользователи и роли</li>
                    <li className={`tab-title__item ${activeSetupTab===SETUP_TAB_NOTIFICATIONS? 'tab-title__item_active navigation-link':''}`}>Уведомления</li>
                    <li className={`tab-title__item ${activeSetupTab===SETUP_TAB_DELIVERY? 'tab-title__item_active':''} navigation-link`}>Доставка</li>
                </ul>
                <div className="tab-content">
                    {activeSetupTab===SETUP_TAB_PROFILE? <TabProfile userId={userId}/>:''}
                    {activeSetupTab===SETUP_TAB_CUSTOMER? <TabCustomer defaultCustomer={defaultCustomer} setDefaultCustomer={setDefaultCustomer} userId={userId}/>:''}
                </div>
            </div>
        </section>
    );
};

export default SetupPage;