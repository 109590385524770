import React, { useEffect, useState, useContext } from "react";
import { ELSI_STATIC_URL, findNotEmptyImageName, makeUrl, NavigationBlockContext } from "../../const/const";
import NavigationBlock from "./Navigation-block";
import { MAKE_CATEGORY_REQUEST, getFetchRequest, postFetchRequestWithSignal, MAKE_PRODUCT_REQUEST, SEARCH_PRODUCT_REQUESTS } from "../../store/requests";
import { ErrorHandler } from "./ErrorHandler";
import { Pagination } from "./Pagination";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "./helper";
import Loader from "./Loader";

const getAllTableHeaders = (listOfProducts) => {
    if (listOfProducts&&listOfProducts.length) {
        const list = [];
        for (let i = 0; i < listOfProducts.length; i++) {
            if(listOfProducts[i].attributes) {
                listOfProducts[i].attributes.map(it => list.includes(it.attributeKey) || it.attributeKey === 'Category' ? '' : list.push(it.attributeKey));
            }
        }
        return list.sort();
    }
    return [];
};

const getAllTableValues = (attributeName, listOfProducts) => {
    if (listOfProducts&&listOfProducts.length) {
        const list = [];
        for (let i = 0; i < listOfProducts.length; i++) {
            if(listOfProducts[i].attributes) {
                listOfProducts[i].attributes.map(it => it.attributeKey !== attributeName || list.includes(it.attributeValue) || it.attributeValue === '-' ? '' : list.push(it.attributeValue));
            }    
        }
        return list.sort();
    }
    return [];
};

const findValueAttribute = (key, listOfAtributes) => {
    let result = '-';
    if(listOfAtributes&&listOfAtributes.length) {
        listOfAtributes.forEach(it => it.attributeKey === key ? result = it.attributeValue : '');
    }
    return result;
};

const findActiveFilterValue = (value, filterName, arrayOfFilters) => {
    let result = false;
    arrayOfFilters.forEach(it => it[filterName] === value? result=true:'');
    return result;
};

const checkActiveCategory = (filterName, arrayOfFilters) => {
    let result = false;
    if(arrayOfFilters&&arrayOfFilters.length) {
        arrayOfFilters.forEach(it => Object.keys(it)[0]===filterName?result=true:'');
    }
    return result;
};

const handleCreatePath = (id, CustomRouter, pageNum, params, listOfFilters, filter, listOfFiltersWasChanged, q) => {
    let result = '';
    if(id) {
        result += CustomRouter.findCurrentPath(`/${id}`) + '?';
    } else {
        result += CustomRouter.findCurrentPath('/search') + '?';
    }
    result += `offset=${pageNum?pageNum:DEFAULT_OFFSET}&limit=${params.limit?params.limit:DEFAULT_LIMIT}`;
    if(q) {
        result += `&q=${q}`;
    }
    if(listOfFilters.length || (filter&&!listOfFiltersWasChanged)) {
        result += `&filter=${listOfFilters.length?JSON.stringify(listOfFilters):filter}`;
    }
    return result;
};

const FiltersList = ({listOfHeaderTitles, handleAddFilterItemInList, productsList, listOfFilters}) => {
    try {
        return (
            <div className="categories-item__filters">
                {listOfHeaderTitles.length ? listOfHeaderTitles.map((filterName, index) => {
                    return (
                        <div key={filterName + index} className="categories-item__filters-item categories-item__filters-list-item">
                            <h2 className="categories-item__filters-title">{filterName}</h2>
                            <ul className="categories-item__filters-list">
                                {getAllTableValues(filterName, productsList).map(listItem => {
                                    return (
                                        <li key={listItem+filterName} onClick={() => handleAddFilterItemInList(listItem, filterName)} className={`categories-item__filters-item ${checkActiveCategory(filterName, listOfFilters)?'categories-item__filters-item_unactive':''}
                                            ${findActiveFilterValue(listItem, filterName, listOfFilters)?'categories-item__filters-item_active':''} navigation-link`}>{listItem}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                }) : ''}
            </div>
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};

const TableHeadersList = ({listOfHeaderTitles}) => {
    try {
        return (
            listOfHeaderTitles.length ? listOfHeaderTitles.map((attr, index) => {
                return (
                    <th key={attr + index} className="categories-item__table-item">{attr}</th>
                );
            }) : null
        );
    } catch (error){
        console.log(error);
        return null;
    }
};

const TableProductsList = ({productsList, listOfHeaderTitles, CustomRouter, handleClick}) => {
    try {
        return (
            productsList.map((it, index) => {
                return (
                    <tr key={it.partNumber + index} className="categories-item__table-row">
                        <td className="categories-item__table-item categories-item__table-item-img">
                            {it.images && it.images.length && findNotEmptyImageName(it.images) ? 
                                <img className="categories-item__table-item-photo" 
                                    src={findNotEmptyImageName(it.images) && `${ELSI_STATIC_URL}/${it.id}/${findNotEmptyImageName(it.images)}`} 
                                    alt={`${it.description} photo`}/> 
                                :
                                '-'}
                        </td>
                        <td className="categories-item__table-item categories-item__table-partnumber navigation-link" 
                            onClick={(e) => handleClick(e, it.id)}>
                            <a className="categories-item__partnumber" href={CustomRouter.findCurrentPath(`/product/${it.id}`)}>
                                {it.partNumber}
                            </a>
                        </td>
                        {listOfHeaderTitles.length ? listOfHeaderTitles.map(keyItem => {
                            return (
                                <td key={keyItem+'key'} className="categories-item__table-item">{findValueAttribute(keyItem, it.attributes)}</td>
                            );
                        }) : ''}
                    </tr>
                );
            })
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};
 
export default function CategoryItem() {    
    const [ currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter ] = useContext(NavigationBlockContext);
    const id = CustomRouter.getParams('id');
    const filter = CustomRouter.getSearchParams('filter');
    const q = CustomRouter.getSearchParams('q');
    const limit = CustomRouter.getSearchParams('limit');
    const offset = CustomRouter.getSearchParams('offset');
    const [productsList, setProductsList] = useState([]);
    const [listOfHeaderTitles, setListOfHeaderTitles] = useState([]);
    const [listOfFilters, setListOfFilters] = useState([]);
    const [pagesNumber, setPagesNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [category, setCategory] = useState({});
    const [parentCategory, setParentCategory] = useState([]);
    const [count, setCount] = useState(limit?+limit:DEFAULT_LIMIT);
    const [loading, setLoading] = useState(true);
    const [listOfFiltersWasChanged, setListOfFiltersWasChanged] = useState(false);
    let controller = new AbortController();

    const successGetPhotoCallback = async (response, productList, index) => {
        if(response.ok) {
            const data = await response.json();
            productList[index].images[0].name = data.resourceName;
        }
        productList&&setProductsList([...productList]);
        setLoading(false);
    };

    const succesCallbackGetProducts = (result) => {
        setLoading(true);
        setListOfHeaderTitles(getAllTableHeaders(result.items));
        setPagesNumber(result.pagesMax);
        setCurrentPage(result.page);
        if(result.items&&result.items.length) {
            for(let i=0; i<result.items.length; i++) {
                if(result.items[i].images&&result.items[i].images.length&&!result.items[i].images[0].name) {
                    const data = {
                        type: "IMAGE",
                        url: result.items[i].images[0].externalUrl,
                    };
                    postFetchRequestWithSignal(makeUrl([MAKE_PRODUCT_REQUEST,'/', result.items[i].id, '/resource/']), data, (response)=>successGetPhotoCallback(response, result.items, i), errorCallback, controller, true);
                } else {
                    result.items&&setProductsList(result.items);
                    setLoading(false);
                }
            }
        } else {
            setLoading(false);
            setProductsList([]);
        }
    };

    const succesSearchCallback = (result) => {
        if(result.items&&result.items.length===1) {
            CustomRouter.navigate(`/product/${result.items[0].id}`);
        } else {
            result.items && result.items.length ? setProductsList(result.items):setProductsList([]);
            setListOfHeaderTitles(getAllTableHeaders(result.items));
            setPagesNumber(result.pagesMax);
            setCurrentPage(result.page);
            setLoading(false);
        }
    };

    const successGetCategoryInfoCallback = (response, isParent=false) => {
        setCurrentPath(q?'/search':`/category/${id}`);
        if(isParent){
            setParentCategory([...parentCategory, response]);
            setCategories([...parentCategory, response]);
        } else {
            setCategory(response);
            setTitle(response.categoryName);
        }
        if(response.parentId) {
            getFetchRequest(makeUrl([MAKE_CATEGORY_REQUEST, '/', response.parentId, '/']), (response) => successGetCategoryInfoCallback(response, true), errorCallback);
        }
    };

    const errorCallback = (error) => {
        if (error.name === 'AbortError') {
            return null;
        } else {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (q) {
            setLoading(true);
            setTitle('Каталог');
            getFetchRequest(makeUrl([SEARCH_PRODUCT_REQUESTS, `?q=${q}&limit=${DEFAULT_LIMIT}&offset=${DEFAULT_OFFSET}`]), succesSearchCallback, errorCallback);
            setCurrentPath(CustomRouter.findCurrentPath('/search'));
            if(filter) {
                setListOfFilters(JSON.parse(filter));
            }
        } else if(!q&&!id&&filter) {
            setListOfFilters(JSON.parse(filter));
        }
        return () => {
            controller.abort();
            setParentCategory([]);
            setCategories([]);
        };
    }, [q]);

    useEffect(() => {
        if (id) {
            getFetchRequest(makeUrl([MAKE_CATEGORY_REQUEST, '/', id, '/']), successGetCategoryInfoCallback, errorCallback);
            if(filter) {
                setListOfFilters(JSON.parse(filter));
            } else {
                setListOfFilters([]);
            }
        } else {
            if(!q) {
                setLoading(false);
                setProductsList([]);
            }
        }
        return () => {
            controller.abort();
            setParentCategory([]);
            setCategories([]);
        };
    }, [id, filter]);

    useEffect(()=> {
        if (id||q) {
            if(listOfFilters.length||(filter&&!listOfFiltersWasChanged)) {
                handleGetProducts(offset?offset:DEFAULT_OFFSET, {filter: listOfFilters.length? JSON.stringify(listOfFilters):filter}, limit?limit:count);
            } else if(id) {
                handleGetProducts(offset?offset:DEFAULT_OFFSET, null, limit?limit:count);
            }
        } else if (!id||!q) {
            handleGetProducts(offset?offset:DEFAULT_OFFSET, {filter: JSON.stringify(listOfFilters)}, limit?limit:count);
        }
        return () => {
            controller.abort();
            setParentCategory([]);
            setCategories([]);
        };
    },[listOfFilters]);

    const handleGetProducts = (pageNum, filterParams='', countItem=null) => {
        setLoading(true);
        let params = { offset: pageNum };
        if(filterParams) {
            params.filter = filterParams.filter;
        }
        if(countItem) {
            params.limit = countItem;
            setCount(countItem);
        } else {
            params.limit = count;
        }
        const path = handleCreatePath(id, CustomRouter, pageNum, params, listOfFilters, filter, listOfFiltersWasChanged, q);
        const pathArray = path.split('?');
        CustomRouter.navigate({pathname: pathArray[0], query: pathArray[1]});
        if (q||!id) {
            if(q) {
                params.q = q;
            }
            getFetchRequest(makeUrl([SEARCH_PRODUCT_REQUESTS,'?', new URLSearchParams(params).toString()]), succesCallbackGetProducts, errorCallback);
        } else {
            getFetchRequest(makeUrl([MAKE_CATEGORY_REQUEST, '/', id, '/product/?', new URLSearchParams(params).toString()]), succesCallbackGetProducts, errorCallback);
        }
    };

    const handleAddFilterItemInList = (value, filterName) => {
        setListOfFiltersWasChanged(true);
        if(findActiveFilterValue(value, filterName, listOfFilters)) {
            setListOfFilters(prev=> prev.filter(it => it[filterName]!==value));
        } else {
            setListOfFilters(prev => [...prev, {[`${filterName}`]:value}]); 
        }
    };

    const handleClick = (e, id) => {
        e.preventDefault();
        CustomRouter.navigate(`/product/${id}`);
    };
    useEffect(() => {
        if(typeof window === "object"&&document && document.getElementById("wrap")) {
            console.log('start');
            document.getElementById("wrap").addEventListener("scroll", function(){
                console.log(this.scrollTop);
                const translate = "translate(0,0px)";
                this.querySelector("thead").style.transform = translate;
            });
        }
    }, []);

    return (
        <>
            <NavigationBlock />
            <section className={`categories-item ${!loading&&productsList.length===0&&'categories-item_display'} container`}>
                <h1 className="categories-item__title">{(q||!id) ? 'Поиск' : category.categoryName}</h1>
                {!loading&&productsList&&productsList.length ?
                    <>
                        <FiltersList listOfHeaderTitles={listOfHeaderTitles} handleAddFilterItemInList={handleAddFilterItemInList} 
                            productsList={productsList} listOfFilters={listOfFilters}/>
                        <div className="categories-item__filters-buttons">
                            <button className="button categories-item__filters-button" onClick={()=>setListOfFilters([])}>Reset</button>
                        </div>
                        <div className="categories-item__table-wrapper" id="wrap">
                            <table className={`categories-item__table ${listOfHeaderTitles.length<10?'categories-item__table_display':''}`}>
                                <thead className="categories-item__table-header">
                                    <tr>
                                        <th className="categories-item__table-item">Photo</th>
                                        <th className="categories-item__table-item">part no.</th>
                                        <TableHeadersList listOfHeaderTitles={listOfHeaderTitles} />
                                    </tr>
                                </thead>
                                <tbody className="categories-item__table-body">
                                    <TableProductsList productsList={productsList} listOfHeaderTitles={listOfHeaderTitles} CustomRouter={CustomRouter} handleClick={handleClick}/>
                                </tbody>
                            </table>
                        </div>
                    </> : loading? <Loader/>: <ErrorHandler />}
            </section>
            {loading||(productsList&&!productsList.length)?'':<Pagination currentPage={currentPage} pageMax={pagesNumber} handleGetProducts={handleGetProducts} count={count}/>}
        </>
    );
};