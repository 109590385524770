import React, { createContext } from 'react';

export const ELSI_API_URL = 'BACKEND_API_URL';
export const ELSI_SHOP_URL = "FRONTEND_SHOP_URL";
export const ELSI_STATIC_URL = 'STATIC_API_URL';
export const ELSI_WEBSOCKET_URL = 'WEBSOCKET_API_URL';

export const MAIN_PAGE = '/';
export const PRODUCTS_PAGE = '/catalog';
export const CATEGORY_PAGE = '/category';
export const PRODUCT_PAGE = '/product';
export const COMPANY_PAGE = '/company';
export const DELIVERY_PAGE = '/delivery';
export const CONTACTS_PAGE = '/contacts';
export const MANUFACTURERS_PAGE = '/manufacturers';
export const TECHNICAL_DOCUMENTS_PAGE = '/tech-docs';

export const PAGE_MENU_HOME = 'PAGE_MENU_HOME';
export const PAGE_MENU_CATALOG = 'PAGE_MENU_CATALOG';
export const PAGE_MENU_ORDERS = 'PAGE_MENU_ORDERS';
export const PAGE_MENU_REQUESTS = 'PAGE_MENU_REQUESTS';
export const PAGE_MENU_ACCOUNTING = 'PAGE_MENU_ACCOUNTING';
export const PAGE_MENU_SETUP = 'PAGE_MENU_SETUP';

export const ADD_PRODUCT_TO_ORDER_POPUP_KEY = 'add-product-to-order';
export const SEND_PRICES_REQUEST = 'send-prices-request';

export const EMPTY_IMAGE_SRC = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export const PAGE_MENU_LIST = {
    [PAGE_MENU_HOME]: {
        key: 'home',
        title: 'Домашняя страница',
    },
    [PAGE_MENU_CATALOG]: {
        key: 'catalog',
        title: 'Каталог',
    },
    [PAGE_MENU_ORDERS]: {
        key: 'orders',
        title: 'Заказы',
    },
    [PAGE_MENU_REQUESTS]: {
        key: 'requests',
        title: 'Запросы',
    },
    [PAGE_MENU_ACCOUNTING]: {
        key: 'accounting',
        title: 'Бухгалтерия',
    },
    [PAGE_MENU_SETUP]: {
        key: 'setup',
        title: 'Настройки',
    },
};

export const SETUP_TAB_PROFILE = 'profile';
export const SETUP_TAB_CUSTOMER = 'customer';
export const SETUP_TAB_USERS = 'users';
export const SETUP_TAB_NOTIFICATIONS = 'notifications';
export const SETUP_TAB_DELIVERY = 'delivery';

export const handleGetImageUrl = (productId, image) => {
    let result ='';
    if(productId, image) {
        result = `${ELSI_STATIC_URL}/${productId}/${image}`;
    }
    return result;
};


export const errorCallback = (error) => {
    if (error.name === 'AbortError') {
        return null;
    } else {
        console.log(error);
    }
};

export const succesCallbackEmpty = (response) => {
};

export const makeUrl = (params) => {
    const result = `${ELSI_API_URL}` + params.join('');
    return result;
};

export const findNotEmptyImageName = (array) => {
    let result = '';
    if(array&&array.length) {
        array.forEach(it => it.name?result=it.name:'');
    }
    return result;
};

export const NavigationBlockContext = createContext();