import React, { useContext } from 'react';
import { PRODUCTS_PAGE, NavigationBlockContext, MAIN_PAGE } from '../../const/const';
import { ChevronIcon } from '../../appearance/icons/chevron_icon';

export default function NavigationBlock() {
    const [ currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter ] = useContext(NavigationBlockContext);
    const handleNavigate = (url) => {
        if(url === -1) {
            CustomRouter.goBack();
        } else {
            CustomRouter.navigate(url);
        }
    };
    try {
        return (
            <>
                <section className={`navigation-block container ${currentPath.includes('product')&&CustomRouter.needToUseCabinetStyles()&&'navigation-block__product'}`}>
                    <div className="navigation-goback navigation-group" onClick={() => handleNavigate(-1)}>
                        <a href="#" className="navigation-item" >Назад</a>
                    </div>
                    <div className="navigation-group">
                        <a href={MAIN_PAGE} className="navigation-item" onClick={() => handleNavigate(CustomRouter.needToUseCabinetStyles()?'/home':'/')}>Главная</a>
                        {currentPath !== PRODUCTS_PAGE&&currentPath !== '/catalog'&&currentPath !== '/search' ?
                            <>
                                <ChevronIcon nameOfClass='navigation-icon__separate' />
                                <a href={PRODUCTS_PAGE} className="navigation-item" onClick={() => handleNavigate(CustomRouter.needToUseCabinetStyles()?"/catalog":"/products")}>Каталог</a>
                            </> : ''}
                        {categories&&categories.length ? categories.map(it => {
                            return (
                                <React.Fragment key={it.categoryId}>
                                    <ChevronIcon nameOfClass='navigation-icon__separate' />
                                    <a href='#' className='navigation-item' onClick={() => handleNavigate(CustomRouter.findCurrentPath(`/${it.categoryId}`))}>{it.categoryName}</a>
                                </React.Fragment>
                            );
                        }):''}
                        <ChevronIcon nameOfClass='navigation-icon__separate' />
                        <a className='navigation-item navigation-item_current' href={'#'}>{title}</a>
                    </div>
                </section>
            </>
        );
    } catch(error) {
        console.log(error);
        return null;
    }
};