import React from 'react';
import { ErrorHandler } from './sharedComponents/ErrorHandler';

const AccountingPage = ({ isCollapsed }) => {
    try {
        return (
            <section className={`workspace requests-page ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} `}>
                <h1 className="requests-page__title">Бухгалтерия</h1>
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default AccountingPage;