import React, { useState, useEffect } from 'react';
import { LinkIcon } from '../../appearance/icons/link_icon';
import { errorCallback, makeUrl, succesCallbackEmpty } from '../../const/const';
import { MAKE_CUSTOMER_REQUEST, getFetchRequest, GET_INFO_FTS, MAKE_USER_REQUEST, postFetchRequest } from '../../store/requests';

const validateTin = (value, setError) => {
    let result = false;
    let tin = value;
    setError({ type: '', message: '' });
    if (typeof tin === 'number') {
        tin = tin.toString();
    } else if (typeof tin !== 'string') {
        tin = '';
    }

    if (!tin.length) {
        setError({ type: 'empty', message: 'ИНН пуст' });
    } else if (/[^0-9]/.test(tin)) {
        setError({ type: 'number', message: 'ИНН может состоять только из цифр' });
    } else if ([10, 12].indexOf(tin.length) === -1) {
        setError({ type: 'length', message: 'ИНН может состоять только из 10 или 12 цифр' });
    } else {
        const checkDigit = function (tin, coefficients) {
            var n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * tin[i];
            }
            return parseInt(n % 11 % 10);
        };
        switch (tin.length) {
        case 10:
            var n10 = checkDigit(tin, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(tin[9])) {
                result = true;
            }
            break;
        case 12:
            var n11 = checkDigit(tin, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            var n12 = checkDigit(tin, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if ((n11 === parseInt(tin[10])) && (n12 === parseInt(tin[11]))) {
                result = true;
            }
            break;
        }
        if (!result) {
            setError({ type: 'wrong', message: 'Неправильное ИНН' });
        }
    }
    return result;
};

const checkTinUniqueness = (tin, listOfCustomers, setError) => {
    let result = true;
    listOfCustomers.forEach(it => it.tin===tin?result=false:'');
    if(!result) {
        setError({type:'unique', message:'Пользователь с таким ИНН уже существует'});
    }
    return result;
};

const TabCustomer = ({userId, setDefaultCustomer}) => {
    const [addCustomerFormIsOpen, setAddCustomerFormIsOpen] = useState(false);
    const [tin, setTin] = useState('');
    const [kpp, setKpp] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [legalAddress, setLegalAddress] = useState('');
    const [error, setError] = useState({ type: '', message: '' });
    const [customersList, setCustomersList] = useState([]);

    const succesCustomerInfoCallback = (response) => {
        if(response.length) {
            setCustomersList(response);
        } else {
            setAddCustomerFormIsOpen(true);
        }
    };

    const succesCustomerByTinCallback = (response) => {
        setKpp(response.kpp);
        setOrganizationName(response.name);
        setLegalAddress(response.legal_address);
    };

    const saveNewCustomerCallback = (response) => {
        setAddCustomerFormIsOpen(false);
        if(customersList.length===0) {  
            setCustomersList(prev=> [...prev, {id:response.id, tin, kpp, legalAddress, name: organizationName, isDefault: true}]);
            handleSetDefault(response);
        } else {
            setCustomersList(prev=> [...prev, response]);
        }
    };

    useEffect(() => {
        if(userId) {
            getFetchRequest(makeUrl([MAKE_USER_REQUEST, userId ,'/customer/']), succesCustomerInfoCallback, errorCallback);
        }
    }, []);

    const handleFillTin = (e) => {
        setTin(e.target.value);
        setError({type: '', message: ''});
        if (validateTin(e.target.value, setError)) {
            const pathTin = e.target.value;
            getFetchRequest(makeUrl([GET_INFO_FTS, pathTin, '/']), succesCustomerByTinCallback, errorCallback);
        }
    };

    const handleSetDefault = (item) => {
        const coppiedArray = [...customersList];
        coppiedArray.forEach(it => {
            if (it.id === item.id) {
                it.isDefault = true;
            } else {
                it.isDefault = false;
            }
        });
        setCustomersList(coppiedArray);
        setDefaultCustomer(item.name);
        postFetchRequest(makeUrl([MAKE_CUSTOMER_REQUEST, item.id, '/default/']), '', succesCallbackEmpty, errorCallback);
    };

    const handleSaveNewCustomer = () => {
        if (tin && checkTinUniqueness(tin, customersList, setError)) {
            const data = {tin, kpp, legalAddress, name: organizationName};
            postFetchRequest(makeUrl([MAKE_USER_REQUEST, userId, '/customer/']), data, saveNewCustomerCallback, errorCallback);
        }
        
    };

    const handleOpenNewCustomerForm = () => {
        setTin('');
        setKpp('');
        setLegalAddress('');
        setOrganizationName('');
        setError({ type: '', message: '' });
        setAddCustomerFormIsOpen(!addCustomerFormIsOpen);
    };

    return (
        <>
            {!addCustomerFormIsOpen ? customersList.length ? 
                <ul className='setup-customer__list'>
                    {customersList.map(it => {
                        return (
                            <li key={it.name + it.isDefault} className="setup-customer-info">
                                <span className="setup-info__name">
                                    {it.name}
                                </span>
                                <label><input checked={it.isDefault} onChange={() => handleSetDefault(it)} type="checkbox"/></label>
                            </li>
                        );
                    })}
                </ul> : '' : <p className="setup-customer__new">ДЛЯ ОФОРМЛЕНИЯ ПОКУПКИ СОЗДАЙТЕ ПОКУПАТЕЛЯ</p>}
            {!addCustomerFormIsOpen ? <div className="custom-select__container setup-customer__select-container">
                <div className="custom-select__text setup-customer__select-text" onClick={handleOpenNewCustomerForm}>
                    Добавить нового покупателя
                    <LinkIcon nameOfClass="custom-select__logo setup-customer__select-logo navigation-link" />
                </div>
            </div> : ''}
            {addCustomerFormIsOpen ? <form className="profile-form setup-customer__form">
                <label name="inn" className="profile-form__item">ИНН
                    <input className="profile-form__input" value={tin} onChange={(e) => handleFillTin(e)} />
                    {error.type&&error.type!=='unique'? <p className='alert'>{error.message}</p> : ''}
                </label>
                <label name="kpp" className="profile-form__item">КПП
                    <input className="profile-form__input"  value={kpp} onChange={(e) => setKpp(e.target.value)}/>
                </label>
                <label name="organization-name" className="profile-form__item">Наименование организации
                    <input className="profile-form__input" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)}/>
                </label>
                <label name="address" className="profile-form__item">Юридический адрес
                    <input className="profile-form__input" value={legalAddress} onChange={(e) => setLegalAddress(e.target.value)}/>
                </label>
                <button className="button__save setup-customer__button" type="button" onClick={handleSaveNewCustomer}>Сохранить</button>
                {error.type&&error.type==='unique'? <p className='alert alert__bottom'>{error.message}</p> : ''}
            </form> : ''}
        </>
    );
};

export default TabCustomer;