import React from 'react';
import { GoBackIcon } from '../../appearance/icons/goback_icon';

const PageNumbers = ({ pageMax, currentPage, handleGetProducts, setCount }) => {
    const pages = [];
    if (pageMax < 5) {
        for (let i = 1; i < (pageMax + 1); i++) {
            pages.push(<li key={i} className={`pagination__number ${i == currentPage ? "pagination__number_active" : ""}`} onClick={(e) => handleGetProducts(e, i)}>{i}</li>);
        }
    } else {
        if (currentPage > 3) {
            pages.push(<li key={1} className={`pagination__number pagination__number_first`} onClick={(e) => handleGetProducts(e, 1)}>{1}</li>);
        }
        for (let i = 1; i < (pageMax + 1); i++) {
            if (currentPage === 1) {
                i < 4 && pages.push(<li key={i} className={`pagination__number ${i == currentPage ? "pagination__number_active" : ""}`} onClick={(e) => handleGetProducts(e, i)}>{i}</li>);
            } else if (currentPage === pageMax) {
                i > currentPage - 3 && pages.push(<li key={i} className={`pagination__number ${i == currentPage ? "pagination__number_active" : ""}`} onClick={(e) => handleGetProducts(e, i)}>{i}</li>);
            } else {
                (i === currentPage - 1 || i === currentPage + 1 || i===currentPage ) && pages.push(<li key={i} className={`pagination__number ${i == currentPage ? "pagination__number_active" : ""}`} onClick={(e) => handleGetProducts(e, i)}>{i}</li>);
            }
        }
        if (currentPage === 1 || currentPage < pageMax - 1) {
            pages.push(<li key={'last'} className={`pagination__number pagination__number_last`} onClick={(e) => handleGetProducts(e, pageMax)}>{pageMax}</li>);
        }
    }
    try {
        return (
            <ul className={`pagination__list`}>{pages}</ul>
        );
    } catch(error) {
        console.log(error);
        return null;
    }
};

export const Pagination = ({ pageMax, currentPage, handleGetProducts, count }) => {
    const handleGetPage = (e, pageNumber, count = null) => {
        e.preventDefault();
        e.stopPropagation();
        (pageNumber !== currentPage || count) && handleGetProducts(pageNumber, null, count);
    };

    const handleChangeLimit = (e) => {
        handleGetPage(e, currentPage, e.target.value);
    };

    return (
        <section className="pagination container">
            <div className='pagination__block'>
                {currentPage === 1 ? "" :
                    <>
                        <span className="pagination__item" onClick={(e) => handleGetPage(e, 1)}>первая</span>
                        <span className='pagination__nav' onClick={(e) => handleGetPage(e, currentPage - 1)}><GoBackIcon /></span>
                    </>}
                <PageNumbers pageMax={pageMax} currentPage={currentPage} handleGetProducts={handleGetPage} />
                {currentPage === pageMax ? "" :
                    <>
                        <span className='pagination__nav' onClick={(e) => handleGetPage(e, currentPage + 1)}><GoBackIcon nameOfClass="pagination__icon" /></span>
                        <span className="pagination__item" onClick={(e) => handleGetPage(e, pageMax)}>последняя</span>
                    </>}
            </div>
            <div className='pagination__select'>PER PAGE
                <select className='pagination__select-item' value={count} onChange={(e) => handleChangeLimit(e)}>
                    <option className='pagination__select-option' value='10'>10</option>
                    <option className='pagination__select-option' value='25'>25</option>
                    <option className='pagination__select-option' value='50'>50</option>
                    <option className='pagination__select-option' value='100'>100</option>
                </select>
            </div>
        </section>
    );
};