import React from 'react';

const GoToRegistrationButton = ({ customRouter }) => {
    const goToLoginPage = () => {
        customRouter.navigate(`/login`);
    };

    return (
        <button className="button product-price__cart-button" type="button" onClick={goToLoginPage}>
            Зарегистрироваться
        </button>
    );
};

export default GoToRegistrationButton;
