import React, { useEffect, useState, useContext } from "react";
import { errorCallback, makeUrl, NavigationBlockContext } from "../../const/const";
import { MAKE_CATEGORY_REQUEST, getFetchRequest } from "../../store/requests";
import { ErrorHandler } from "./ErrorHandler";
import NavigationBlock from "./Navigation-block";
import Loader from "./Loader";

const getProductsConclusion = (number) => {
    const n = Math.abs(number) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return 'товаров'; }
    if (n1 > 1 && n1 < 5) { return 'товара'; }
    if (n1 == 1) { return 'товар'; }
    return 'товаров';
};

const sortFunction = (a, b) => {
    if ( a.categoryName < b.categoryName ){
        return -1;
    }
    if ( a.categoryName > b.categoryName ){
        return 1;
    }
    return 0;  
};

const sortSubCategories = (categoriesList) => {
    let coppiedArray = [...categoriesList];
    coppiedArray = coppiedArray.sort(sortFunction);
    if(coppiedArray.length) {
        for(let i = 0; i< coppiedArray.length; i++) {
            if(coppiedArray[i].subCategories&&coppiedArray[i].subCategories.length) {
                coppiedArray[i].subCategories = coppiedArray[i].subCategories.sort(sortFunction);
            }
        }
    }
    return coppiedArray;
};

const ListOfCategories = ({categoriesList, handleClick, CustomRouter}) => {
    try {
        return (
            categoriesList.map(it => {
                return (
                    <ul key={it.categoryId} className="categories-page__list">
                        <a href={CustomRouter.findCurrentPath(`/${it.categoryId}`)}>
                            <h2 className="categories-page__list-title" onClick={(e)=> handleClick(e, it.categoryId)}>{it.categoryName}
                                <span className="categories-page__title-content">{it.productsNumber?it.productsNumber+' '+getProductsConclusion(it.productsNumber):''}</span>
                            </h2>
                        </a>
                        {it.subCategories&&it.subCategories.length ? it.subCategories.map(item => {
                            return (
                                <a href={CustomRouter.findCurrentPath(`/${item.categoryId}`)} className="categories-page__item" key={item.categoryId} onClick={(e)=> handleClick(e, item.categoryId)}>
                                    <span className="categories-page__item-title">{item.categoryName}</span>
                                    <span className="categories-page__item-content">{item.productsNumber?item.productsNumber+' '+getProductsConclusion(item.productsNumber):''}</span>
                                </a>
                            );
                        }) : ''}
                    </ul>
                );
            })
        );
    } catch (error) {
        console.log(error);
        return <ErrorHandler />;
    }
};

export default function AllProducts() {
    const [categoriesList, setCategoriesList] = useState([]);
    const [ currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter ] = useContext(NavigationBlockContext);

    const succesCallback = (response) => {
        setCategoriesList(sortSubCategories(response)); 
    };

    useEffect(() => {
        getFetchRequest(makeUrl([MAKE_CATEGORY_REQUEST, '/']), succesCallback, errorCallback);
        setCurrentPath(CustomRouter.findCurrentPath('/catalog'));
        setTitle('Каталог');
        setCategories([]);
    }, []);

    const handleClick = (e, id) => {
        e.preventDefault();
        CustomRouter.navigate(`/${id}`);
    }; 
    return (
        <>
            <NavigationBlock />
            {categoriesList.length ? <section className="categories-page container">
                <h1 className="categories-page__title">Категории товаров</h1>
                <ListOfCategories categoriesList={categoriesList} handleClick={handleClick} CustomRouter={CustomRouter}/>
            </section> : <Loader />}
        </>
    );
};