import React, { useEffect, useState } from 'react';
import { errorCallback, makeUrl } from '../const/const';
import { getFetchRequest, MAKE_PRODUCT_REQUEST, MAKE_REQUEST } from '../store/requests';
import { ErrorHandler } from './sharedComponents/ErrorHandler';

const RequestTable = ({mainPage, requestsList}) => {
    return (
        <table className="table orders__table">
            <thead>
                <tr className="table-header">
                    <th className="table-header__item">Номер запроса</th>
                    {mainPage?'':<th className="table-header__item">Id пользователя</th>}
                    <th className="table-header__item">Email</th>
                    <th className="table-header__item">Тип запроса</th>
                    <th className="table-header__item">Парт-номер</th>
                    {mainPage?'':<th className="table-header__item">Производитель</th>}
                </tr>
            </thead>
            <tbody>
                {requestsList.map(it => {
                    return (
                        <tr key={it.id} className="table-row">
                            <td className={`table__item ${it.id?'table__item_active':''}`}>{it.id}</td>
                            {mainPage?'':<td className={`table__item ${it.userId?'table__item_active':''}`}>{it.userId}</td>}
                            <td className={`table__item ${it.userEmail?'table__item_active':''}`}>{it.userEmail}</td>
                            <td className={`table__item ${it.requestType?'table__item_active':''}`}>{it.requestType}</td>
                            <td className={`table__item ${it.partNumber?'table__item_active':''}`}>{it.partNumber}</td>
                            {mainPage?'':<td className={`table__item ${it.manufacturer?'table__item_active':''}`}>{it.manufacturer}</td>}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const RequestsPage = ({ isCollapsed, mainPage=null }) => {
    const [requestsList, setRequestsList] = useState([]);  
    
    const successGetProductInfoCallback = (response, responseData) => {
        const coppiedArray = [...responseData];
        coppiedArray.forEach(it =>{
            if(it.requestProductId===response.id) {
                it.partNumber = response.partNumber;
                it.manufacturer = response.manufacturer;
            }
        });
        if(mainPage) {
            setRequestsList(coppiedArray.slice(0,4));
        } else {
            setRequestsList(coppiedArray);
        }
    };

    const succesGetRequestCallback = (result) => {
        if(result.length) {
            for(let i=0; i< result.length; i++) {
                getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', result[i].requestProductId, '/']), (response)=>successGetProductInfoCallback(response, result), errorCallback);
            } 
        }
    };

    useEffect(() => {
        getFetchRequest(makeUrl([MAKE_REQUEST]), succesGetRequestCallback, errorCallback);
    },[]);

    try {
        if(mainPage) {
            return <RequestTable mainPage={mainPage} requestsList={requestsList}/>;
        }
        return (
            <section className={`workspace requests-page ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} `}>
                {requestsList.length?
                    <>
                        <h1 className="requests-page__title">Запросы</h1>
                        <RequestTable mainPage={mainPage} requestsList={requestsList} />
                        
                    </>
                    : <div className='requests-page__empty'>Ничего не найдено</div>}
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default RequestsPage;