import { errorCallback, makeUrl } from "../../const/const";
import { MAKE_PRODUCT_REQUEST, SEARCH_PRODUCT_REQUESTS, getFetchRequest } from "../../store/requests";

export const GET_IMAGE_TIMEOUT = 200;

export const hintsService = {
    searchInputValue: '',
    listOfHints: [],
    observersList: [],
    lastActionTime: '',
    getImageTimeout: null,
    setSearchValue(searchValue) {
        this.searchInputValue = searchValue;
        this.lastActionTime = Date.now();
        this.makeHintsSearch();
    },
    getSearchValue() {
        return this.searchInputValue;
    },
    makeHintsSearch() {
        try {
            if(this.searchInputValue.length > 2) {
                getFetchRequest(makeUrl([SEARCH_PRODUCT_REQUESTS, `hint/?q=${this.searchInputValue}`]), (response) => {
                    this.listOfHints = response;
                    this.notifyHintsObservers();
                }, errorCallback);
            } 
        } catch (err) {
            console.log(err);
        }
    },
    registerHintsObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterHintsObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyHintsObservers() {
        this.observersList.forEach(observer => observer(this.listOfHints));
    },
    getHints() {
        return this.listOfHints;
    },
    changeLastActionTime(lastActionTime) {
        this.lastActionTime = lastActionTime;
    },
    getHintImage(productId, callback) {
        if((Date.now() - this.lastActionTime) > GET_IMAGE_TIMEOUT) {
            getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', productId, '/?onlyImage=true']), 
                (response) => {
                    const currentProductIndex = this.listOfHints.findIndex(it => it.productId === productId);
                    this.listOfHints[currentProductIndex].images = response;
                    callback(response);
                }, errorCallback);
        }
    },
    clearHintsList() {
        this.listOfHints = [];
        this.notifyHintsObservers();
    },
}; 
