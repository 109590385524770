import React from 'react';
import { LinkIcon } from '../appearance/icons/link_icon';
import { useNavigate } from 'react-router-dom';
import OrdersPage from './OrdersPage';
import RequestsPage from './RequestPage';
import { PAGE_MENU_LIST, PAGE_MENU_ORDERS, PAGE_MENU_REQUESTS } from '../const/const';

const HomePage = ({ isCollapsed, handleChangeTab}) => {
    const navigate = useNavigate();
    return (
        <section className={`workspace workspace_align ${isCollapsed===null ? '': isCollapsed? 'workspace_collapsed' : 'workspace_full-width'}`}>
            <article className="orders">
                <h1 className="orders__title">Заказы</h1>
                <a className="link orders__link navigation-link" href={`/${PAGE_MENU_LIST[PAGE_MENU_ORDERS].key}`} onClick={() => navigate('/orders')}>
                    <span className="link__text">Посмотреть все </span>
                    <LinkIcon nameOfClass='link__icon' />
                </a>
                <OrdersPage isCollapsed={isCollapsed} mainPage={true}/>
            </article>
            <article className={`requests ${isCollapsed?'requests_collapsed':''}`}>
                <h1 className="requests__title">Запросы</h1>
                <a className={`link requests__link navigation-link ${isCollapsed?'requests__link_collapsed':''}`} href={`/${PAGE_MENU_LIST[PAGE_MENU_REQUESTS].key}`} onClick={() => navigate('/requests')}>
                    <span className="link__text">Посмотреть все </span>
                    <LinkIcon nameOfClass="link__icon" />
                </a>
                <RequestsPage isCollapsed={isCollapsed} mainPage={true} />
            </article>
            <article className="notifications">
                <h1 className="notifications__title">Уведомления</h1>
                <ul className="notifications__list">
                    <li className="notifications__item">
                        <span>Заказ # 210456 от 21.04.2022:</span>
                        <span className="notifications__item-line"></span>
                        <span className="notifications__item-status">Оплачен</span>
                    </li>
                    <li className="notifications__item">
                        <span>Заказ # 210769 от 31.05.2022:</span>
                        <span className="notifications__item-line"></span>
                        <span className="notifications__item-status">Скомплектован</span>
                    </li>
                    <li className="notifications__item">
                        <span>Запрос # 11675 от 11.06.2022:</span>
                        <span className="notifications__item-line"></span>
                        <span className="notifications__item-status">Создан</span>
                    </li>
                </ul>
            </article>
        </section>
    );
};

export default HomePage;