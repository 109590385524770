export const popupOpenService = {
    popupsObject: {},
    callToOpen(popupKey, popupParams) {
        try {
            this.popupsObject[popupKey](popupParams);
        } catch(err) {
            console.log(err);
        }
    },

    registerPopup(popupKey, openStateFunction) {
        this.popupsObject[popupKey] = openStateFunction;
    },

    unRegisterPopup(popupKey) {
        try {
            delete this.popupsObject[popupKey];
        } catch(err) {
            console.log(err);
        }
    },
};
