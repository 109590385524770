import React from "react";

export const HomeIcon = ({nameOfClass}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" className={nameOfClass} viewBox="0 0 27.91 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M17.6,25.5h-7.5v-8.66c0-1.02,.83-1.85,1.85-1.85h3.8c1.02,0,1.85,.83,1.85,1.85v8.66ZM3.89,10.46v13.19c0,1.02,.83,1.85,1.85,1.85H21.96c1.02,0,1.85-.83,1.85-1.85V10.46M.5,13.85L13.85,.5l13.56,13.56" /></g></g></svg>;
};

export const SetupIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M25.5,14.68v-3.36c0-.46-.37-.83-.83-.83h-2c-.23-.9-.59-1.76-1.06-2.55l1.41-1.41c.33-.33,.33-.85,0-1.18l-2.38-2.38c-.33-.32-.85-.32-1.18,0l-1.41,1.41c-.79-.46-1.64-.82-2.55-1.06V1.33c0-.46-.37-.83-.83-.83h-3.36c-.46,0-.83,.37-.83,.83v2c-.91,.23-1.76,.59-2.55,1.06l-1.41-1.41c-.33-.33-.85-.33-1.18,0l-2.38,2.38c-.32,.33-.32,.85,0,1.18l1.41,1.42c-.46,.79-.82,1.64-1.06,2.55H1.33c-.46,0-.83,.37-.83,.83v3.36c0,.46,.37,.83,.83,.83h2c.23,.9,.59,1.76,1.06,2.54l-1.41,1.42c-.32,.32-.32,.85,0,1.18l2.38,2.38c.33,.32,.85,.32,1.18,0l1.41-1.41c.79,.46,1.64,.82,2.55,1.06v2c0,.46,.37,.83,.83,.83h3.36c.46,0,.83-.37,.83-.83v-2c.9-.23,1.76-.59,2.55-1.06l1.42,1.41c.33,.32,.85,.32,1.18,0l2.38-2.38c.33-.32,.33-.85,0-1.18l-1.41-1.42c.46-.79,.82-1.64,1.06-2.54h2c.46,0,.83-.37,.83-.83Zm-7.66-1.68c0,2.68-2.17,4.84-4.84,4.84s-4.84-2.17-4.84-4.84,2.17-4.84,4.84-4.84,4.84,2.17,4.84,4.84Z" /></g></g></svg>;
};

export const AccountingIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M16.08,6.85L4.02,18.9m15.15-8.96L7.12,22M24.33,3.83l-2.14-2.14M8.68,23.56l-6.23-6.24M15.91,3.85l.17,3,2.95,.12,.15,2.97,2.97,.15m0,0L25.5,.5,15.91,3.85m6.24,6.23L7.71,24.52c-1.3,1.3-3.42,1.3-4.72,0l-1.52-1.52c-1.3-1.3-1.3-3.42,0-4.72L15.91,3.85" /></g></g></svg>;
};

export const CatalogIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.01 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M3.64,18.67h6.23M3.64,14.08h6.23M3.64,9.48h6.23M3.64,4.88h6.23m6.27,13.79h6.23m-6.23-4.6h6.23m-6.23-4.6h6.23m-6.23-4.6h6.23M9.45,.5c1.97,0,3.56,1.59,3.56,3.56V25.5h0c-.07-1.12-.99-2.01-2.13-2.01H.5V.5H9.45Zm7.12,0c-1.97,0-3.56,1.59-3.56,3.56V25.5h0c.06-1.12,.99-2.01,2.13-2.01h10.37V.5h-8.95Z" /></g></g></svg>;
};

export const OrdersIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.47 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M6.2,4.67H27.97l-2.77,7.66c-.37,1.01-1.33,1.69-2.41,1.69H8.04m15.8,9.28c0-1.22-.99-2.2-2.2-2.2s-2.2,.99-2.2,2.2,.99,2.2,2.2,2.2,2.2-.99,2.2-2.2Zm-9.51,0c0-1.22-.99-2.2-2.2-2.2s-2.2,.99-2.2,2.2,.99,2.2,2.2,2.2,2.2-.99,2.2-2.2ZM.5,.5H3.28c1.22,0,2.28,.87,2.51,2.07l2.77,14.06c.24,1.2,1.29,2.07,2.51,2.07h14.1" /></g></g></svg>;
};

export const RequestsIcon = ({nameOfClass}) => {
    return <svg className={nameOfClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.47 26"><g id="a" /><g id="b"><g id="c"><path className="d" d="M3.09,13.52h5.88M3.09,9.21H14.35M3.09,4.91H14.35m5.8,16.77l3.82,3.82m-12.67-7.39c0,2.84,2.3,5.14,5.14,5.14s5.14-2.3,5.14-5.14-2.3-5.14-5.14-5.14-5.14,2.3-5.14,5.14Zm1.04,3.1H2.15c-.91,0-1.65-.74-1.65-1.65V2.15c0-.91,.74-1.65,1.65-1.65H15.3c.91,0,1.65,.74,1.65,1.65V12.97" /></g></g></svg>;
};