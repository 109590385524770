import React, { useState, useEffect, useRef } from "react";
import { SearchIcon } from '../../appearance/icons/search_icon';
import { getFetchRequest, MAKE_PRODUCT_REQUEST, postFetchRequestWithSignal } from "../../store/requests";
import { errorCallback, makeUrl, ELSI_STATIC_URL, findNotEmptyImageName, EMPTY_IMAGE_SRC } from "../../const/const";

const GET_IMAGE_TIMEOUT = 200;

const makeFilterValue = (item) => {
    const result = [{[item.productAttributeKey]: item.productAttributeValue}];
    return JSON.stringify(result);
};

function isElementOutViewport(el){
    var rect = el.getBoundingClientRect();
    return rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight;
}

const createInnerHTML = (product) => {
    let result = '';
    if(findNotEmptyImageName(product.images) && product.productId && product.isVisible) {
        result += `<img class="search-options__image" 
            onerror="this.onerror=null;this.src='${EMPTY_IMAGE_SRC}';this.style.border='1px solid #b6b6b6';"
            src='${ELSI_STATIC_URL}/${product.productId}/${findNotEmptyImageName(product.images)}'
            alt="${findNotEmptyImageName(product.images)}"/>`;
    }
    product.productPartNumber ? 
        result += (product.productPartNumber + `<span class='search-options__key'>${product.productManufacturerName}</span>`)
        : 
        result += (product.productAttributeValue + `<span class='search-options__key'>${product.productAttributeKey}</span>`);
    
    return result;
};

const createAllFiledsString = (item) => {
    let result = '';
    for(let key in item) {
        result += item[key];
    } 
    return result;
};

export function OrderSearchInput({handleKeyDown, productSearch, handleChangeSearchInput, listOfSearchOptions, CustomRouter, handleSelectSearchOption, placeholder}) {
    const [lastActionTime, setLastActionTime] = useState(Date.now());
    const [searchList, setSearchList] = useState([]);
    const listRef = useRef();
    let timeout;
    let controller = new AbortController();

    const handleFindImagesName = async (imagesLink, index) => {
        if(searchList[index].isVisible && imagesLink && !findNotEmptyImageName(imagesLink) && 
            imagesLink[0] && imagesLink[0].externalUrl) {
            const data = {
                type: "IMAGE",
                url: imagesLink[0].externalUrl,
            };
            await postFetchRequestWithSignal(makeUrl([MAKE_PRODUCT_REQUEST,'/', searchList[index].productId, '/resource/']), data, (response) => {
                searchList[index].images = [{name: response.resourceName}];
            }, errorCallback, controller);
        }
        else if(findNotEmptyImageName(imagesLink)) {
            searchList[index].images = imagesLink;
        }
        await setSearchList([...searchList]);
    };

    const findClickFunctionValue = (e, item) => {
        if(item.productPartNumber) {
            handleSelectSearchOption(e, item.productId);
        } else {
            CustomRouter.navigate(`/search/?filter=${makeFilterValue(item)}`);
        }
    };

    const handleGetProductImages = async () => {
        const searchData = searchList;
        for(let i = 0; i < searchData.length; i++) {
            if(searchData[i].productId && productSearch && !searchData[i].imageName && searchData[i].isVisible && !findNotEmptyImageName(searchData[i].images) ) {
                await getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', searchData[i].productId, '/?onlyImage=true']), 
                    (response) => {
                        handleFindImagesName(response, i);
                    }, errorCallback);
            }
        }
    };

    const handleUpdateActionTime = () => {
        if(listRef.current.scrollTop !== 0) {
            setLastActionTime(Date.now());
        }
    };

    const handleUpdateVisibleElements = () => {
        if(listRef && listRef.current && searchList.length) {
            for(let child of listRef.current.children) {
                if(child.id) {
                    searchList.forEach(it => it.productId === child.id ? it.isVisible = !isElementOutViewport(child) : null);
                }
            }
        }
        handleGetProductImages();
    };

    useEffect(() => {
        if(listOfSearchOptions.length) {
            setSearchList(listOfSearchOptions);
        } else {
            setSearchList([]);
        }
    }, [listOfSearchOptions]);

    useEffect(() => {
        if(timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => {
            handleUpdateVisibleElements();
        }, GET_IMAGE_TIMEOUT);
        return () => {
            clearTimeout(timeout);
        };
    }, [lastActionTime]);

    useEffect(() => {
        if(searchList.length) {
            listRef.current = document.getElementsByClassName('chapche-event-scroll')[0];
            handleUpdateVisibleElements();
        }
    }, [searchList.length]);

    useEffect(() => {
        if(listRef && listRef.current) {
            listRef.current.addEventListener('scroll', handleUpdateActionTime);
            return () => {
                if(listRef && listRef.current) {
                    listRef.current.removeEventListener('scroll', handleUpdateActionTime);
                }
            };
        }
    }, [listRef.current]);

    return (
        <>
            <label className={`menu-common__search menu-middle__search ${placeholder?'product-orders__filter-label':''}`} onKeyDown={handleKeyDown}>
                <SearchIcon />
                <input className={`menu-common__search-input menu-middle__search-input ${searchList.length ? 'menu-common__search-input_active menu-middle__search-input_active' : ''} ${placeholder?'product-orders__filter-search':''}`}
                    placeholder={placeholder ? placeholder : "Поиск по парт-номеру и параметрам..."}
                    value={productSearch}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                        e.target.value.length > 2 && clearTimeout(timeout);
                        handleChangeSearchInput(e);
                        listRef.current && listRef.current.scrollTo(0, 0);
                    }} />
            </label>
            {searchList && searchList.length ?
                <ul className='search-options__list chapche-event-scroll' onClick={(e) => e.stopPropagation()} ref={listRef}>
                    {searchList.map(item => {
                        return (
                            <li key={createAllFiledsString(item)}
                                className='search-options__item' onClick={(e) => findClickFunctionValue(e, item)}
                                id={item.productId}
                                dangerouslySetInnerHTML={{ __html: createInnerHTML(item)}}></li>
                        );
                    })}
                </ul> : ''}
        </>
    );
};