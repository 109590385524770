import React, { useState, useEffect } from 'react';
import { cartManager, showPricesWithSpaces, sortByQuantity } from './cartManager';

export const findOnePriceValue = (price) => {
    const currentPriceValue = price.quantities.sort(sortByQuantity).findLast(it => it.minQty <= price.count);
    if(currentPriceValue) {
        return currentPriceValue.priceValue;
    } else {
        return 0;
    }
};

const findAllSelectedPricesValues = (pricesList) => {
    let result = 0;
    pricesList.forEach(it => result += it.count * findOnePriceValue(it));
    return result;
};

export const getAllPricesCount = (pricesList) => {
    let result = 0;
    pricesList.forEach(it => result += it.count);
    return result;
};

const CartTotalPrice = () => {
    const [currentPrices, setCurrentPrices] = useState([]);

    const changeCurrentPrice = (pricesValue) => {
        setCurrentPrices([...pricesValue]);
    };

    useEffect(() => {
        cartManager.registerCartObserver(changeCurrentPrice);
        return () => cartManager.unRegisterCartObserver(changeCurrentPrice);
    }, []);

    return (
        currentPrices.length && getAllPricesCount(currentPrices) > 0 ?
            <div>
                <h4 className="product-price__cart-total">
                    <span>Итого: </span>
                    <p className="product-price__cart-quantity">{cartManager.isCountEnough() ? getAllPricesCount(currentPrices) : 0} шт.</p>
                </h4>
                <p className="product-price__cart-price">
                    {cartManager.isCountEnough() ? showPricesWithSpaces(findAllSelectedPricesValues(currentPrices).toFixed(2)) : 0} 
                    {currentPrices[0] && currentPrices[0].currency === 'USD' ? '$':'₽'}</p>
                
            </div>
            :
            null
    );
};

export default CartTotalPrice;
