import React, { useEffect, useState, useRef } from 'react';
import { hintsService } from './hintsService';
import HintItem from './HintItem';

const HintsList = ({ CustomRouter }) => {
    const [stateHash, setStateHash] = useState('');
    const listRef = useRef();    
    
    const resetScroll = () => {
        listRef.current && listRef.current.scrollTo(0, 0);
    };

    const changeHintsList = (newHintsList) => {
        if (newHintsList.length) {
            setStateHash(prev => ! prev); 
            resetScroll();
        } else {
            setStateHash('');
        }
    };

    useEffect(() => {
        hintsService.registerHintsObserver(changeHintsList);
        return () => hintsService.unRegisterHintsObserver(changeHintsList);
    }, []);

    return (
        stateHash !== '' ?
            <ul className='search-options__list chapche-event-scroll' onClick={(e) => e.stopPropagation()}>
                {hintsService.getHints().map((item, index) => (
                    <React.Fragment key={item.productId + index}>
                        <HintItem 
                            productId={item.productId} 
                            productPartNumber={item.productPartNumber} 
                            productManufacturerId={item.productManufacturerId} 
                            productManufacturerName={item.productManufacturerName} 
                            productAttributeValue={item.productAttributeValue} 
                            productAttributeKey={item.productAttributeKey} 
                            CustomRouter={CustomRouter} />
                    </React.Fragment>
                ))}
            </ul> 
            : 
            ''
    );
};

export default HintsList;
