import React, {useState, useEffect} from 'react';
import { CollapseIcon } from '../appearance/icons/collapse_icon';
import { ExitIcon } from '../appearance/icons/exit_icon';
import { LogoIcon } from '../appearance/icons/logo_icon';
import { ELSI_SHOP_URL, errorCallback, makeUrl, PAGE_MENU_ACCOUNTING, PAGE_MENU_CATALOG, PAGE_MENU_LIST, PAGE_MENU_SETUP} from '../const/const';
import { getFetchRequest, GET_CURRENT_USER, LOGOUT_REQUEST, postFetchRequest, MAKE_USER_REQUEST, SEARCH_PRODUCT_REQUESTS } from '../store/requests';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { SearchHandler } from './sharedComponents/SearchHandler';
import { hintsService } from './sharedComponents/hintsService';
import { CustomReactRouter } from '../services/customRouter';

const getTitle = (activeKey) => {
    let result = '';
    if(activeKey) {        
        const preparedKey = activeKey.split('/')[1];
        Object.values(PAGE_MENU_LIST).forEach(it => it.key === preparedKey?result=it.title:'');
    }
    return result;
};

export const handleGetDefaultCustomer = (dataArray) => {
    let result = '';
    if(dataArray.length) {
        dataArray.forEach(it => it.isDefault? result=it.name:'');
    }
    return result;
}; 


const Header = ({setIsCollapsed, isCollapsed, defaultCustomer, setDefaultCustomer}) => {
    const [managerName, setManagerName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [scroll, setScroll] = useState(0);
    const [dataIsLoad, setDataIsLoad] = useState(false);
    const router = useNavigate();
    const CustomRouter = new CustomReactRouter(router);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const succesGetCurrentUserCallback = (response) => {
        if(response.id) {
            handleGetManager(response.id);
            handleGetCustomerInfo(response.id);
        }
    };

    const succesGetManagerInfoCallback = async (response) => {
        if(response.ok) {
            const result = await response.json();
            setManagerName(result.name);
        }
    };

    const succesGetCustomersListInfo = async (response) => {
        setDefaultCustomer(handleGetDefaultCustomer(response));
        await setDataIsLoad(true);
    };

    const errorGetDataCallback = (error) => {
        if (error.name === 'AbortError') {
            return null;
        } else {
            console.log(error);
        }
        setDataIsLoad(true);
    };

    useEffect(()=> {
        getFetchRequest(makeUrl([GET_CURRENT_USER]), succesGetCurrentUserCallback, errorGetDataCallback);
    },[]);

    const succesCallback = (response) => {
        if(response.status === 200) {
            localStorage.removeItem("token");
            window.location.href = `${ELSI_SHOP_URL}/login`;
        }
    };

    const handleGetManager = (userId) => {
        getFetchRequest(makeUrl([MAKE_USER_REQUEST, userId, '/manager/']), succesGetManagerInfoCallback, errorGetDataCallback, true);
    };

    const handleGetCustomerInfo = (userId) => {
        getFetchRequest(makeUrl([MAKE_USER_REQUEST, userId, '/customer/']), succesGetCustomersListInfo, errorCallback);
    };

    const handleLogout = () => {
        postFetchRequest(makeUrl([LOGOUT_REQUEST]), '', succesCallback, errorCallback, true);
    };

    const handleCloseSearchList = (e) => {
        e.stopPropagation();
        hintsService.clearHintsList();
    };

    return (
        <header className="header" onClick={handleCloseSearchList}>
            <div className="menu-common">
                <Link to='/home' className="menu-common__logo navigation-link">
                    <LogoIcon />
                </Link>
                <SearchHandler CustomRouter={CustomRouter}/>
                <p className="menu-common__manager-info">
                    {dataIsLoad?
                        <>
                            <span className="menu-common__manager-info-label">Ваш менеджер: </span>
                            <span className="menu-common__manager-info-name">{managerName}</span>
                        </>:''}
                </p>
                <p className="menu-common__contacts">
                    8-800-775-02-42
                </p>
                <div className="menu-common__exit navigation-link" onClick={handleLogout}>
                    <ExitIcon />
                    <span className="menu-common__exit-text">Выход</span>
                </div>
            </div>
            <div className={`info ${scroll>120&&'info_sticky'}`}>
                <CollapseIcon nameOfClass={`info__collapse-icon ${isCollapsed ? 'info__collapse-icon_collapsed' : 'info__collapse-icon_full-width'} navigation-link`} setIsCollapsed={setIsCollapsed}/>
                <h3 className={`info__page-title  ${isCollapsed ? 'info__page-title_collapsed' : ''} navigation-link`} onClick={() => setIsCollapsed(!isCollapsed)}>{getTitle(location.pathname)}</h3>
                {dataIsLoad?<a href={`/${PAGE_MENU_LIST[PAGE_MENU_SETUP].key}`} onClick={()=> navigate(`/${PAGE_MENU_LIST[PAGE_MENU_SETUP].key}`)}
                    className={`info__customer ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_SETUP].key)? 'info__customer_setup':''} navigation-link`} >
                    <span className="info__customer-title">Покупатель: </span>
                    <span className="info__customer-organization-name">
                        {defaultCustomer?defaultCustomer: 'Выберите покупателя'}
                    </span>
                </a>:''}
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_ACCOUNTING].key}`} onClick={()=> navigate(`/${PAGE_MENU_LIST[PAGE_MENU_ACCOUNTING].key}`)}
                    className="info__balance navigation-link" >Баланс: 0 руб.</a>
            </div>
        </header>
    );
};

export default Header;