import React, { useState } from "react";
import { LinkIcon } from "../appearance/icons/link_icon";

const optionsList = [
    {
        name:'Физическое лицо',
        id: 1,
        value: 'INDIVIDUAL',
    },
    {
        name: 'Закупка',
        id: 2,
        value: 'PURCHASE',
    },
    {
        name: 'Разработка',
        id: 3,
        value: 'DEVELOPMENT',
    },
    {
        name: 'Логистика',
        id: 4,
        value: 'LOGISTICS',
    },
    {
        name: 'Руководство',
        id: 5,
        value: 'MANAGEMENT',
    },
];

const getTitleByValue = (value) => {
    let result = '';
    optionsList.forEach(it => it.value===value?result=it.name:'');
    return result;
};


const CustomSelect = ({customerRole, setCustomerRole}) => {
    const [state, setState] = useState({
        defaultSelectText: customerRole?getTitleByValue(customerRole) : "Ваш род деятельности",
        value: customerRole? customerRole:'',
        showOptionList: false,
        optionsList: optionsList,
    });
    const handleListOpen = () => {
        setState({
            defaultSelectText: customerRole?getTitleByValue(customerRole) : "Ваш род деятельности",
            value: customerRole? customerRole:'',
            showOptionList: !state.showOptionList,
            optionsList: optionsList,
        });
    };
    const handleOptionClick = (title, value) => {
        setState({
            defaultSelectText: title,
            value,
            showOptionList: false,
            optionsList: optionsList,
        });
        setCustomerRole(value);
    };

    return (
        <div className="custom-select__container">
            <div
                className="custom-select__text"
                onClick={handleListOpen}
                onChange={handleListOpen}
            >
                {state.defaultSelectText}
                <LinkIcon nameOfClass="custom-select__logo navigation-link" />
            </div>
            {state.showOptionList && (
                <ul className="custom-select__options">
                    {optionsList.map(option => {
                        return (
                            <li
                                className="custom-select__option"
                                key={option.id}
                                onClick={()=>handleOptionClick(option.name, option.value)}
                            >
                                {option.name}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CustomSelect;