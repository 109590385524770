import { CustomRouter } from "../components/sharedComponents/helper";
import { ELSI_SHOP_URL } from "../const/const";
import { useParams, useSearchParams } from 'react-router-dom';

export class CustomReactRouter extends CustomRouter {
    constructor(router) {
        super(router);
        this.router = router;
    }

    getParams(key) {
        const params = useParams();
        return params[key];
    }

    getSearchParams(key) {
        const [searchParams] = useSearchParams();
        return searchParams.get(key);
    }

    goBack() {
        this.router(-1);
    }

    needToUseCabinetStyles() {
        return true;
    }

    navigate(path) {
        if(typeof path === 'object') {
            this.router(path.pathname+'?'+path.query); 
        } else if(path === '/login') {
            window.location.href = `${ELSI_SHOP_URL}/login`;
        } else {
            this.router(this.findCurrentPath(path));
        }   
    }

    findCurrentPath(path) {
        if(path === '/home'||path.startsWith('/catalog')) {
            return `${path}`;
        } else {
            return `/catalog${path}`;
        }
    }
}