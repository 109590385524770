import React, { useEffect } from 'react';
import AddToOrderButton from './AddToOrderButton';
import GoToRegistrationButton from './GoToRegistrationButton';
import CartTotalPrice from './CartTotalPrice';
import CartProductsList from './CartProductsList';
import { cartManager } from './cartManager';

const userIsLogin = (customRouter) => {
    return customRouter.needToUseCabinetStyles()||localStorage.getItem('token');
};

const CartHandler = ({ customRouter }) => {

    useEffect(() => {
        return () => cartManager.clearService();
    }, []);

    return (
        <div className="product-price__cart">
            <CartProductsList />
            <CartTotalPrice />
            {userIsLogin(customRouter) ?
                <AddToOrderButton />
                :
                <GoToRegistrationButton customRouter={customRouter} />}
        </div>
    );
};

export default CartHandler;
