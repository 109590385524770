import React, { useEffect } from 'react';
import { AccountingIcon, CatalogIcon, HomeIcon, OrdersIcon, RequestsIcon, SetupIcon } from '../appearance/icons/menu_icons';
import {
    PAGE_MENU_ACCOUNTING,
    PAGE_MENU_CATALOG,
    PAGE_MENU_HOME,
    PAGE_MENU_LIST,
    PAGE_MENU_ORDERS,
    PAGE_MENU_REQUESTS,
    PAGE_MENU_SETUP,
} from '../const/const';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Home = ({ isCollapsed, setIsCollapsed }) => {
    const [searchParams] = useSearchParams();
    const q = searchParams.get('q');
    const filter = searchParams.get('filter');
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        if(q||filter) {
            navigate(`/${PAGE_MENU_LIST[PAGE_MENU_CATALOG].key}`);
        }
    },[]);

    const handleChangeTab = (tabName) => {
        setIsCollapsed(null);
        navigate(`/${PAGE_MENU_LIST[tabName].key}`);
    };
    return (
        <nav className={`page-menu ${isCollapsed===null ? '': isCollapsed? 'page-menu_collapsed':'page-menu_full-width'}`}>
            <ul className={`menu__list ${isCollapsed===null ? '': isCollapsed? 'menu__list_collapsed':''}`}>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_HOME].key}`} onClick={()=>handleChangeTab(PAGE_MENU_HOME)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`} >
                    <HomeIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_HOME].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_HOME].key)? 'menu__title_active':''} ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'}`}>Домашняя страница</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Самая актуальная информация по заказам и запросам</p>
                </a>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_CATALOG].key}`} onClick={()=>handleChangeTab(PAGE_MENU_CATALOG)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`} >
                    <CatalogIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_CATALOG].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'} ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_CATALOG].key)? 'menu__title_active':''}`}>Каталог</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Запросы по ценам на товары из наличия и под заказ</p>
                </a>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_ORDERS].key}`} onClick={()=>handleChangeTab(PAGE_MENU_ORDERS)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`}>
                    <OrdersIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_ORDERS].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'} ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_ORDERS].key)? 'menu__title_active':''}`}>Заказы</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Совершение покупки, отгрузки, статусы заказов, печать документов</p>
                </a>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_REQUESTS].key}`} onClick={()=>handleChangeTab(PAGE_MENU_REQUESTS)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`} >
                    <RequestsIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_REQUESTS].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'} ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_REQUESTS].key)? 'menu__title_active':''}`}>Запросы</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Запросы по ценам на товары из наличия и под заказ</p>
                </a>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_ACCOUNTING].key}`} onClick={()=>handleChangeTab(PAGE_MENU_ACCOUNTING)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`} >
                    <AccountingIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_ACCOUNTING].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'} ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_ACCOUNTING].key)? 'menu__title_active':''}`}>Бухгалтерия</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Договора, авансовые счёт-фактуры, акты сверок, баланс</p>
                </a>
                <a href={`/${PAGE_MENU_LIST[PAGE_MENU_SETUP].key}`} onClick={()=>handleChangeTab(PAGE_MENU_SETUP)}
                    className={`menu__item ${isCollapsed===null ? '': isCollapsed? 'menu__item_collapsed':'menu__item_full-width'} navigation-link`} >
                    <SetupIcon nameOfClass={`menu__icon ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_SETUP].key)?'menu__icon_active':''}`} />
                    <h2 className={`menu__title ${isCollapsed===null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'} ${location.pathname.includes(PAGE_MENU_LIST[PAGE_MENU_SETUP].key)? 'menu__title_active':''}`}>Настройки</h2>
                    <p className={`menu__text ${isCollapsed===null ? '': isCollapsed? 'menu__text_collapsed':'menu__text_full-width'}`}>Реквизиты компании, пользователи, адрес доставки</p>
                </a>
            </ul>
        </nav>
    );
};

export default Home;