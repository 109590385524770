export const handleTransformDate = (dateItem) => {
    return dateItem?dateItem.split('.')[0].slice(0,-3):'';
};

export const DEFAULT_OFFSET = 1;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_LIMIT_STATISTIC = 5;


export class CustomRouter {
    constructor(router) {
        this.router = router;
    }

    getParams() {}

    getSearchParams() {}

    goBack() {}

    needToUseCabinetStyles() {}

    navigate() {}

    findCurrentPath() {}
}