import React, { useState } from 'react';
import { IS_LIMIT_REACHED_KEY_ERROR, cartManager, sortByQuantity } from './cartManager';
import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';

const findMinQty = (price) => {
    return price.quantities.sort(sortByQuantity)[0].minQty;
};

const CountButton = ({ price }) => {
    const [productCount, setProductCount] = useState(0);
    const [error, setError] = useState('');

    const enterProductCount = (e) => {
        setError('');
        if(+e.target.value >= 0) {
            setProductCount(e.target.value);
            const result = cartManager.setProductsCount(price, +e.target.value);
            if(result.error) {
                setError(result.error);
            }
        } 
    };

    const increaseProductCount = () => {
        setError('');
        const currentCount = +productCount === 0 ? findMinQty(price) : +productCount + 1;
        setProductCount(() => currentCount);
        const result = cartManager.setProductsCount(price, currentCount);
        if(result.error) {
            setError(result.error);
        }
    };

    const decreaseProductCount = () => {
        setError('');
        setProductCount(prev => +prev - 1);
        const result = cartManager.setProductsCount(price, +productCount - 1);
        if(result.error) {
            setError(result.error);
        }
    };

    return (
        <td className="product-price__table-item product-price__table-item_height">
            <div className={`product-price__table-block ${error ? 'product-price__table-item_error' : ''}`}>
                <span className="product-price__table-button product-price__table-button_left"
                    onClick={() => +productCount === 0 ? null : decreaseProductCount()}>
                    {price.stockNumber !== 0 ? 
                        <MinusIcon nameOfClass={`product-price__table-icon ${+productCount !== 0 ? '' : 'product-price__table-icon_disabled'}`} /> 
                        : 
                        ''}
                </span>
                {price.stockNumber !== 0 ?
                    <input className="product-price__table-quantity"
                        value={productCount}
                        onChange={enterProductCount} 
                        onBlur={() => productCount === '' ? setProductCount(0) : null}
                    />
                    :
                    <p className='product-price__table-quantity_disabled'>Нет в наличии</p>
                }
                <span 
                    className="product-price__table-button"
                    onClick={() => +productCount + 1 <= price.stockNumber ? increaseProductCount() : null}>
                    {price.stockNumber !== 0 ? 
                        <PlusIcon nameOfClass={`product-price__table-icon ${+productCount + 1 <= price.stockNumber ? '' : 'product-price__table-icon_disabled'}`} /> 
                        : 
                        ''}
                </span>
                {error ? 
                    <p className="product-price__table-error">
                        {error === IS_LIMIT_REACHED_KEY_ERROR ? `В наличии ${price.stockNumber} шт.` : `Заказ от ${findMinQty(price)} шт.`}
                    </p> 
                    : 
                    ''} 
            </div>
        </td>
    );
};

export default CountButton;
