import React from 'react';

const Loader = ({isProductPrice}) => (
    <section className="loader__wrapper">
        <div className="loader">
            <p className="loader__inside"></p>
        </div>
        {isProductPrice ? <span className="loader__text">Идет загрузка. Пожалуйста подождите...</span> : ''}
    </section>
);

export default Loader;