import React, { useRef, useState, useEffect } from 'react';
import { EMPTY_IMAGE_SRC, findNotEmptyImageName, ELSI_STATIC_URL } from '../../const/const';
import { GET_IMAGE_TIMEOUT, hintsService } from './hintsService';
import { useOnScreen } from './useOnScreen';

const createInnerHTML = (productId, productPartNumber, productManufacturerName, productAttributeValue, productAttributeKey, images, isVisible) => {
    let result = '';
    if(findNotEmptyImageName(images) && productId && isVisible) {
        result += `<img class="search-options__image" 
            onerror="this.onerror=null;this.src='${EMPTY_IMAGE_SRC}';this.style.border='1px solid #b6b6b6';"
            src='${ELSI_STATIC_URL}/${productId}/${findNotEmptyImageName(images)}'
            alt="${findNotEmptyImageName(images)}"/>`;
    }
    productPartNumber ? 
        result += (productPartNumber + `<span class='search-options__key'>${productManufacturerName}</span>`)
        : 
        result += (productAttributeValue + `<span class='search-options__key'>${productAttributeKey}</span>`);
    
    return result;
};

const createAllFiledsString = (productId, productPartNumber, productManufacturerId, productManufacturerName, productAttributeValue, productAttributeKey) => {
    return productId + productPartNumber + productManufacturerId + productManufacturerName + productAttributeValue + productAttributeKey;
};

const makeFilterValue = (productAttributeKey, productAttributeValue) => {
    const result = [{[productAttributeKey]: productAttributeValue}];
    return JSON.stringify(result);
};

const HintItem = ({productId, productPartNumber, productManufacturerId, productManufacturerName, 
    productAttributeValue, productAttributeKey, CustomRouter}) => {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const [images, setImages] = useState([]);

    const routeToThePageFunction = () => {
        if(productPartNumber) {
            CustomRouter.navigate(`/product/${productId}`);
            hintsService.clearHintsList();
        } else {
            hintsService.clearHintsList();
            CustomRouter.navigate(`/search/?filter=${makeFilterValue(productAttributeKey, productAttributeValue)}`);
        }
    };

    const changeImagesList = (imagesLink) => {
        setImages(imagesLink);
    };

    const getProductImages = () => {
        if(productPartNumber && isVisible && images.length === 0) {
            hintsService.getHintImage(productId, changeImagesList);
        }
    };

    useEffect(() => {
        if(isVisible) {
            setTimeout(() => {
                getProductImages();
            }, GET_IMAGE_TIMEOUT);
        }
    }, [productId, isVisible]);
    
    return (
        <li key={createAllFiledsString(productId, productPartNumber, productManufacturerId, productManufacturerName, productAttributeValue, productAttributeKey)}
            className='search-options__item' onClick={routeToThePageFunction}
            ref={ref}
            id={productId}
            dangerouslySetInnerHTML={{ __html: createInnerHTML(productId, productPartNumber, productManufacturerName, productAttributeValue, productAttributeKey, images, isVisible)}}></li>
    );
};

export default HintItem;
