import React, { useState, useEffect } from 'react';
import { SearchIcon } from '../../appearance/icons/search_icon';
import { hintsService } from './hintsService';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './helper';

const createSearchPath = (searchValue, CustomRouter) => {
    return CustomRouter.findCurrentPath('/search') + `?q=${searchValue}&offset=${DEFAULT_OFFSET}&limit=${DEFAULT_LIMIT}`;
};

const SearchInput = ({ CustomRouter }) => {
    const [searchListLength, setSearchListLength] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const handleKeyDown = (e) => {
        if(e.key == "Enter" && searchValue.length > 2) {
            hintsService.clearHintsList();
            CustomRouter.navigate(createSearchPath(searchValue, CustomRouter));
        }
    };

    const changeSearchListLength = (hintsList) => {
        setSearchListLength(hintsList.length);
    };

    useEffect(() => {
        hintsService.registerHintsObserver(changeSearchListLength);
        return () => hintsService.unRegisterHintsObserver(changeSearchListLength);
    }, []);

    return (
        <label className='menu-common__search menu-middle__search' onKeyDown={handleKeyDown}>
            <SearchIcon />
            <input className={`menu-common__search-input menu-middle__search-input ${searchListLength ? 'menu-common__search-input_active menu-middle__search-input_active' : ''}`}
                placeholder="Поиск по парт-номеру и параметрам..."
                value={searchValue}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                    hintsService.setSearchValue(e.target.value);
                }} 
            />
        </label>
    );
};

export default SearchInput;
