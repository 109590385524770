import React from 'react';

const ModalItem = ({ content, handleClose, handleLoad }) => (
    <div className="modal" onClick={(e) => e.stopPropagation()}>
        <p className="modal__content">{content}</p>
        <div className="modal__buttons">
            <button className="button modal__button" onClick={handleLoad}>
                Скачать счет
            </button>
            <button className="button button__cancel modal__button" onClick={handleClose}>
                Отмена
            </button>
        </div>
    </div>
);

export default ModalItem;