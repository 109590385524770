import React, { useEffect, useState, createContext, useContext } from "react";
import { ELSI_STATIC_URL, errorCallback, makeUrl, EMPTY_IMAGE_SRC } from "../../const/const";
import NavigationBlock from "./Navigation-block";
import { getFetchRequest, postFetchRequestWithSignal, MAKE_PRODUCT_REQUEST, GET_STOCKS, MAKE_MANUFACTURER_REQUEST, CONFIGS_URL } from "../../store/requests";
import { ErrorHandler } from "./ErrorHandler";
import { findNotEmptyImageName, NavigationBlockContext, SEND_PRICES_REQUEST } from "../../const/const";
import GetPricePopUp from "./GetPricePopUp";
import { ChevronIcon } from "../../appearance/icons/chevron_icon";
import Loader from "./Loader";
import { clearPricesAsyncService, handleCreateNewPriceAsyncService, registerObserver, unRegisterObserver } from "./priceAsyncService";
import CartHandler from "./CartHandler";
import CountButton from "./CountButton";
import { cartManager, sortByQuantity } from "./cartManager";
import { popupOpenService } from "./popupOpenService";

export const ProductContext = createContext();
const PRODUCT_ATTRIBUTE_CATEGORY = 'Category';
const CONFIG_CAN_GUEST_SEE_PRICES = 'guest-can-see-prices';

const productClassificationList = [
    'rohsStatus',
    'moistureSensitiveLevel',
    'reachStatus',
    'eccn',
    'htsus',
    'californiaProp65',
];

const getValueByKeyClassification = (key, listOfClassification) => {
    let result = '-';
    if(listOfClassification&&listOfClassification[0]&&listOfClassification.length) {
        Object.entries(listOfClassification[0]).forEach(it => it[0]===key? result=it[1]:'');
    }
    return result;
};

const canUserGetPrice = (configs) => {
    if(configs.findIndex(it => it.configName === CONFIG_CAN_GUEST_SEE_PRICES && it.isActive) >= 0) {
        return true;
    }
    return false;
};

const findDatasheetData = (product, key) => {
    let result = '';
    product[key] ? result = product[key] : null;
    return result;
};

const getDatasheetName = (url) => {
    if(url) {
        const result = url.split('/').reverse()[0];
        return result;
    }
    return '';
};

const findCurrentPrice = (price) => {
    const currentPricesList = Object.values(cartManager.getCurrentPrices());
    if(currentPricesList && currentPricesList.length) {
        let result = [];
        currentPricesList.forEach(it => result.push(it.quantities.sort(sortByQuantity).findLast(item => item.minQty <= it.count)));
        return result.find(it => it && it.id === price.id)  ? result.find(it => it && it.id === price.id).id : '';
    } else {
        return '';
    }
};

const checkPricesReady = (pricesArray, priceDataIsAbsence) => {
    return ((priceDataIsAbsence && pricesArray.length) || !priceDataIsAbsence);
};

const generateMainTitle = (partNumber, description) => {
    let result = partNumber;
    if(result && result.length<40 && description && description.length) {
        result+=', ';
        const descriptionData = description.split(' ');
        if(descriptionData.length > 2 && (descriptionData[0]+descriptionData[1]+descriptionData[2]).length<40) {
            result+=descriptionData[0]+' '+descriptionData[1]+' '+descriptionData[2];
        } else if(descriptionData.length> 1 &&(descriptionData[0]+descriptionData[1]).length<40) {
            result+=descriptionData[0]+' '+descriptionData[1];
        } else if(descriptionData[0].length<40) {
            result+=descriptionData[0];
        }
    }
    return result;
};

const MainTitle = ({product}) => {
    try {
        return (
            <h1 className="product-item__title">{generateMainTitle(product.partNumber, product.description)}</h1>
        );
    } catch(error) {
        console.log(error);
        return null;
    }
};

const PhotoItem = ({product}) => {
    try {
        return (
            product.images && product.images.length && findNotEmptyImageName(product.images) ? 
                <img className="product-item__photo-item" alt={`${product.partNumber} photo`} 
                    src={`${ELSI_STATIC_URL}/${product.id}/${findNotEmptyImageName(product.images)}`} /> :
                <img className="product-item__photo-item" src={EMPTY_IMAGE_SRC}/>
        );
    } catch (error) {
        console.log(error);
        return <img className="product-item__photo-item" />;
    }
};

const ManufacturerInfo = ({product, isDescription, handleDownloadDatasheet}) => {
    try {
        return (
            product.manufacturer && product.manufacturer.id ?
                !isDescription ? 
                    handleDownloadDatasheet ? 
                        <p className="product-item__link " key={product.manufacturer.id + 'data'} 
                            onClick={handleDownloadDatasheet}>{getDatasheetName(product.externalDatasheet)}
                        </p>
                        :
                        <p className="product-item__manufacturer" key={product.manufacturer.id + 'mfr'}>
                            <span className="product-item__manufacturer-name">{product.manufacturer.name}</span>
                            {product.manufacturer && product.manufacturer.logo ? 
                                <img className="product-item__manufacturer-image"
                                    onError={(e) => {
                                        e.target.src=EMPTY_IMAGE_SRC;
                                        e.target.onerror = null;
                                    }}
                                    src={`${ELSI_STATIC_URL}/manufacturers/${product.manufacturer.logo}`} /> 
                                :
                                <img className="product-item__manufacturer-image" src={EMPTY_IMAGE_SRC} />}
                        </p>
                    : 
                    <p className="product-item__description" key={product.manufacturer.id + 'desc'}>
                        {product.description}
                    </p>
                :
                ''
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};

const AttributesList = ({product, classificationIsOpen}) => {
    try {
        return (
            product.attributes && product.attributes.length ? product.attributes.map((it, index) => {
                return (
                    it.attributeKey !== PRODUCT_ATTRIBUTE_CATEGORY && (index<10 || classificationIsOpen) ? 
                        <tr key={it.attributeKey+index} className="product-table__row">
                            <td className="product-table__item"><p>{it.attributeKey}</p></td>
                            <td className="product-table__item"><p>{it.attributeValue}</p></td>
                            <td className="product-table__item_checkbox">
                                <input className="product-table__checkbox" id={it.attributeKey+'attribute'} type="checkbox"/>
                                <label className="product-table__label" htmlFor={it.attributeKey+'attribute'}></label>
                            </td>
                        </tr>
                        :
                        null
                );
            }) : null
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};

const ClassificationList = ({product, classificationIsOpen}) => {
    try {
        return (
            productClassificationList.map((it, index) => {
                return (
                    ((product.attributes.length<9&&index+product.attributes.length<9)||classificationIsOpen)?<tr key={it+'attr'} className="product-table__row">
                        <td className="product-table__item"><p>{it}</p></td>
                        <td className="product-table__item"><p>{getValueByKeyClassification(it, product.classifications)}</p></td>
                        <td className="product-table__item_checkbox">
                            <input className="product-table__checkbox" id={it+'attribute'} type="checkbox"/>
                            <label className="product-table__label" htmlFor={it+'attribute'}></label>
                        </td>
                    </tr>:null
                );
            })
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const PricesList = ({pricesArray, activePricesIsChanged, activePrice=null}) => {
    try {
        return (
            pricesArray.length ?
                <ul className="product-table__list">
                    {pricesArray.sort((a,b) => a.minQty - b.minQty).map(item => (
                        <li key={item.id + activePricesIsChanged} 
                            className={`product-price__table-list 
                                ${findCurrentPrice(item) === item.id 
                                    || (activePrice && activePrice.priceId === item.priceId) ? 'product-price__table-list_active':''}`}>
                            <span>{item.minQty}+</span>
                            <span>{item.priceValue.toFixed(2)} {item.currency === 'USD'?'$':'₽'}</span>
                        </li>
                    ))}
                </ul>:null
        );
    } catch(error) {
        console.log(error);
        return null;
    }
};

const createKeyForPricesTable = (price) => {
    return price.manufacturer+price.distributor+price.packer+price.id;
};

const checkPossibilityToCreateWSConnection = (productId, partNumber, configsList) => {
    return productId && partNumber && canUserGetPrice(configsList);
};

export default function ProductItem() {
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({type:'', message:''});
    const [pricesArray, setPricesArray] = useState([]);
    const [classificationIsOpen, setClassificationIsOpen] = useState(false);
    const [configsList, setConfigsList] = useState([]);
    const [activePricesIsChanged, setActivePricesIsChanged] = useState(false);
    const [priceDataIsAbsence, setPriceDataIsAbsence] = useState(false);
    const [ currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter ] = useContext(NavigationBlockContext);
    const id = CustomRouter.getParams('id');
    let controller = new AbortController();
    
    const changePricesArray = (priceData) => {
        if(Array.isArray(priceData)) {
            setPricesArray([...priceData]);
        } else {
            setPriceDataIsAbsence(true);
        }
    };

    useEffect(() => {
        if (checkPossibilityToCreateWSConnection(id, product.partNumber, configsList)) {
            handleCreateNewPriceAsyncService(id, product.partNumber);
            registerObserver(changePricesArray);
        }
        return () => {
            unRegisterObserver(changePricesArray);
            setPricesArray([]);
            cartManager.clearService();
        };
    }, [id, product.partNumber, configsList]);

    const successGetDataCallback = (response, result, data) => {
        if(data.type==="IMAGE") {
            result.images[0].name = response.resourceName;
        } else {
            result.datasheetName = response.resourceName;
        }
        setProduct({...result});
        setLoading(false);
    };

    const successCallback = async (result) => {
        const responseData = await result;       
        if(findDatasheetData(responseData, 'externalDatasheet')) {
            const data = {
                type: "DATASHEET",
                url: findDatasheetData(responseData, 'externalDatasheet'),
            };
            postFetchRequestWithSignal(makeUrl([MAKE_PRODUCT_REQUEST, '/', responseData.id, '/resource/']), data, (response)=>successGetDataCallback(response, result, data), errorCallback, controller);
        }
        if(responseData.images&&responseData.images.length&&!findNotEmptyImageName(responseData.images)) {
            if(responseData.images[0].externalUrl) {
                const data = {
                    type: "IMAGE",
                    url: responseData.images[0].externalUrl,
                };
                postFetchRequestWithSignal(makeUrl([MAKE_PRODUCT_REQUEST,'/', responseData.id, '/resource/']), data, (response)=>successGetDataCallback(response, result, data), errorCallback, controller);
            }
        }
        if(responseData.manufacturer && responseData.manufacturer.logo === null) {
            postFetchRequestWithSignal(makeUrl([MAKE_MANUFACTURER_REQUEST, responseData.manufacturer.id, '/resource/']), null, () => null, errorCallback, controller);
        }
        setTitle(responseData.partNumber);
        setProduct(responseData);
        getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', responseData.id, '/category/']), 
            (response) => response.length&&response[0]&&setCategories(response[0]), errorCallback);
        setLoading(false);
    };

    const changeActivePrices = () => {
        setActivePricesIsChanged(prev => !prev);
    };

    useEffect(() => {
        if (id) {
            setCurrentPath(`/product/${id}`);
            getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', id, '/']), successCallback, errorCallback);
            getFetchRequest(makeUrl([CONFIGS_URL]), (response) => setConfigsList(response), errorCallback);
            cartManager.registerCartObserver(changeActivePrices);
        }
        return () => {
            controller.abort();
            cartManager.unRegisterCartObserver(changeActivePrices);
            setPriceDataIsAbsence(false);
            clearPricesAsyncService();
            setProduct({});
        };
    }, [id]);

    const handleNavigate = () => {
        CustomRouter.navigate(`/login`);
    };

    const handleDownloadDatasheet = () => {
        if(findDatasheetData(product, 'datasheetName')) {
            const link = document.createElement('a');
            link.setAttribute(
                'href',
                `${ELSI_STATIC_URL}/${id}/${findDatasheetData(product, 'datasheetName')}`,
            );
            link.setAttribute(
                'target',
                `_blank`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            setError({type:'datasheet', message: 'Ошибка загрузки файла'});
        }
    };

    const handleOpenPopUp = () => {
        popupOpenService.callToOpen(SEND_PRICES_REQUEST);
    };

    try {
        return (
            loading ? <Loader product={true} /> :
                <>
                    {Object.keys(product).length ? <NavigationBlock /> : ''}
                    <ProductContext.Provider value={[
                        canUserGetPrice(configsList, pricesArray) && checkPricesReady(pricesArray, priceDataIsAbsence), product]
                    }>
                        <GetPricePopUp/>
                    </ProductContext.Provider>
                    <MainTitle product={product} />
                    <div className="product-item__wrapper">
                        <section className="product-item">
                            <div className="product-item__photo">
                                <PhotoItem product={product}/>
                                <p className="product-item__photo-link">
                                    Изображения только для справки
                                    См. технические характеристики продукта
                                </p>
                            </div>
                        </section>
                        <section>
                            <div className="product-item__info">
                                <h3 className="product-item__info-title">Номер по каталогу производителя:</h3>
                                <p className="product-item__partnumber">{product.partNumber}</p>
                                <div className="product-item__wrapper-manufacturer">
                                    <h3 className="product-item__info-title">Бренд / Производитель:</h3>
                                    <ManufacturerInfo product={product} isDescription={false}/>
                                </div>
                                <h3 className="product-item__info-title">Описание:</h3>
                                <ManufacturerInfo product={product} isDescription={true}/>
                                <h3 className="product-item__info-title">Техническая документация:</h3>
                                <ManufacturerInfo product={product} isDescription={false} handleDownloadDatasheet={handleDownloadDatasheet}/>
                            </div>
                        </section>
                        <section className="product-table__block">
                            <h2 className="product-table__title">Технические характеристики: </h2>
                            <p className="product-table__subtitle">Выберите параметр</p>
                            <table className="product-table product-table__attributes">
                                <tbody>
                                    <AttributesList product={product} classificationIsOpen={classificationIsOpen}/>
                                    <ClassificationList product={product} />
                                </tbody>
                            </table>
                            {product.attributes&&product.attributes.length + productClassificationList.length > 9 ? 
                                <p className="product-table__subtitle product-table__subtitle_bottom" onClick={() => setClassificationIsOpen(!classificationIsOpen)}>
                                    {!classificationIsOpen?'Развернуть':'Свернуть'}
                                    <ChevronIcon nameOfClass={`product-table__icon ${classificationIsOpen?'product-table__icon_collapsed':''}`} />
                                </p>:''}
                        </section> 
                    </div>
                    <section className="product-price__block">
                        {canUserGetPrice(configsList) ?
                            <table className="product-table product-price__table">
                                <thead>
                                    <tr className="product-price__table-header">
                                        <th className="product-price__table-item">Модель</th>
                                        <th className="product-price__table-item">Производитель</th>
                                        <th className="product-price__table-item">Наличие</th>
                                        <th className="product-price__table-item">Партия</th>
                                        <th className="product-price__table-item">Правила заказа</th>
                                        <th className="product-price__table-item">Отгрузка</th>
                                        <th className="product-price__table-item">Цена</th>
                                        <th className="product-price__table-item">Количество</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(pricesArray && pricesArray.length) || priceDataIsAbsence ? 
                                        pricesArray.map(price => (
                                            <tr className="product-price__table-row" key={createKeyForPricesTable(price)}>
                                                <td className="product-price__table-item">{price.partNumber}</td>
                                                <td className="product-price__table-item">{price.manufacturer}</td>
                                                <td className="product-price__table-item">{price.stockNumber} шт.</td>
                                                <td className="product-price__table-item"></td>
                                                <td className="product-price__table-item"></td>
                                                <td className="product-price__table-item">
                                                    {price.distributor ? price.distributor : price.source}
                                                    {price.packer ? 
                                                        <p className="product-price__table-packer">Уп. {price.packer}</p> 
                                                        : 
                                                        null}
                                                </td>
                                                <td className="product-price__table-item">
                                                    <PricesList pricesArray={price.quantities} activePricesIsChanged={activePricesIsChanged}/>
                                                </td>
                                                <CountButton price={price}/>
                                            </tr>
                                        ))
                                        : 
                                        <tr><td colSpan="4"><Loader isProductPrice={true} /></td></tr>}
                                </tbody>
                            </table>:<p className="product-price__empty">
                                {CustomRouter.needToUseCabinetStyles()||localStorage.getItem('token') ? "" : 
                                    <>
                                        <span className="product-price__empty-link" onClick={handleNavigate}>
                                            Авторизуйтесь
                                        </span> или 
                                    </>}
                                <span className="product-price__empty-link" onClick={handleOpenPopUp}>
                                    {" "+"отправьте запрос"+" "}
                                </span>
                                на получение цены</p>}
                        {configsList.length ? 
                            canUserGetPrice(configsList) && checkPricesReady(pricesArray, priceDataIsAbsence) ?
                                <CartHandler customRouter={CustomRouter}/>
                                :
                                <div className="product-price__cart product-price__cart-default">
                                    <div className='product-price__cart-title'>
                                        <p>Количество</p>
                                        <p>Цена</p>
                                    </div>
                                    <button className="button product-cart__button product-cart__button-empty-content" onClick={handleOpenPopUp}>
                                        Запросить цену
                                    </button>
                                </div>
                            : null}
                    </section>
                </>
        );
    } catch (error) {
        return <ErrorHandler error={error} />;
    }
};