import React, { useState, useEffect } from 'react';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import OrdersPage from './components/OrdersPage';
import RequestsPage from './components/RequestPage';
import CategoryItem from './components/sharedComponents/CategoryItem';
import SetupPage from './components/SetupPage';
import Header from './components/Header';
import AllProducts from './components/sharedComponents/All-products';
import ProductItem from './components/sharedComponents/ProductItem';
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from 'react-toastify';
import WithWrapper from './components/WithWrapper';
import AccountingPage from './components/AccountingPage';
import { ELSI_SHOP_URL, makeUrl } from './const/const';
import { CHECK_USER_REQUEST, getFetchRequest } from './store/requests';
import { hintsService } from './components/sharedComponents/hintsService';

const App = () => {
    const [isCollapsed, setIsCollapsed] = useState(null);
    const [defaultCustomer, setDefaultCustomer] = useState('');
    const [accessToken, setAccessToken] = useState('');
    injectStyle();    

    const succesCallback = (response) => {
        if(response && response.token) {
            setAccessToken(response.token);
            localStorage.setItem('token', response.token);
        } else {
            window.location.href = `${ELSI_SHOP_URL}/login`;
        }
    };

    const errorCallback = (e) => {
        console.log(e);
        window.location.href = `${ELSI_SHOP_URL}/login`;
    };
    
    useEffect(() => {
        getFetchRequest(makeUrl([CHECK_USER_REQUEST]), succesCallback, errorCallback);
    },[]);

    if(!accessToken) {
        return null;
    }
    
    const handleCloseSearchList = (e) => {
        e.stopPropagation();
        e.preventDefault();
        hintsService.clearHintsList();
    };

    const CategoriesListWithWrapper = WithWrapper(AllProducts, isCollapsed);
    const ProductItemWithWrapper = WithWrapper(ProductItem, isCollapsed);
    const CategoryItemWithWrapper = WithWrapper(CategoryItem, isCollapsed); 

    return (
        accessToken&&<Router>
            <ToastContainer/>
            <Header setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} defaultCustomer={defaultCustomer} setDefaultCustomer={setDefaultCustomer}/>
            <main className="main" onClick={handleCloseSearchList}>
                <Home isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                <Routes>
                    <Route exact path="/" element={<Navigate to="/home" replace />} />
                    <Route exact path="/home" element={<HomePage isCollapsed={isCollapsed}/>} />
                    <Route exact path="/catalog" element={<CategoriesListWithWrapper/>} />
                    <Route exact path="/catalog/:id" element={<CategoryItemWithWrapper/>} />
                    <Route exact path="/catalog/search" element={<CategoryItemWithWrapper/>} />
                    <Route exact path="/catalog/product/:id" element={<ProductItemWithWrapper/>} />
                    <Route exact path="/orders" element={<OrdersPage isCollapsed={isCollapsed}/>} />
                    <Route exact path="/requests" element={<RequestsPage isCollapsed={isCollapsed}/>} />
                    <Route exact path="/accounting" element={<AccountingPage isCollapsed={isCollapsed} />} />
                    <Route exact path="/setup" element={<SetupPage isCollapsed={isCollapsed} defaultCustomer={defaultCustomer} setDefaultCustomer={setDefaultCustomer}/>} />
                </Routes>
            </main>
        </Router>
    );
};

export default App;
