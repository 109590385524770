import React, { useEffect, useState, useContext, useRef } from "react";
import { CloseIcon } from "../../appearance/icons/close_icon";
import { CloseIconSmall } from "../../appearance/icons/close_icon_small";
import { errorCallback, makeUrl, succesCallbackEmpty, ELSI_STATIC_URL, ADD_PRODUCT_TO_ORDER_POPUP_KEY, SEND_PRICES_REQUEST } from "../../const/const";
import { toast } from 'react-toastify';
import { getFetchRequest, GET_CURRENT_USER, MAKE_REQUEST, MAKE_ORDER_REQUEST, postFetchRequest, MAKE_USER_REQUEST } from "../../store/requests";
import { ProductContext } from "./ProductItem";
import { handleTransformDate } from "./helper";
import { OrderSearchInput } from "./OrderSearchInput";
import { ChevronIcon } from '../../appearance/icons/chevron_icon';
import { CheckIcon } from '../../appearance/icons/check_icon';
import { popupOpenService } from "./popupOpenService";
import { cartManager, sortByQuantity } from "./cartManager";

const FILTER_MENU_SEARCH = 'search';
const FILTER_MENU_PARTNUMBER = 'partnumber';
const FILTER_MENU_DATE_CREATED = 'date';
const FILTER_DIRECTION_UP = 'up';
const FILTER_DIRECTION_DOWN = 'down';

const SORT_DROPDOWN_VALUES = {
    [FILTER_MENU_PARTNUMBER]: [
        {
            title: "По возрастанию",
            value: FILTER_DIRECTION_UP,
            type: FILTER_MENU_PARTNUMBER,
        },
        {
            title: "По убыванию",
            value: FILTER_DIRECTION_DOWN,
            type: FILTER_MENU_PARTNUMBER,
        },
    ],
    [FILTER_MENU_DATE_CREATED]: [
        {
            title: "От новых к старым",
            value: FILTER_DIRECTION_UP,
            type: FILTER_MENU_DATE_CREATED,
        },
        {
            title: "От старых к новым",
            value: FILTER_DIRECTION_DOWN,
            type: FILTER_MENU_DATE_CREATED,
        },
    ],
};

const transformToMs = (date) => {
    const data = new Date(date).getTime();
    return data;
};

const DropdownListLayout = ({activeFilterMenu, filterValue, handleChangeFilterValue}) => (
    <div className="product-orders__dropdown">
        <ul className="product-orders__dropdown-list">
            {SORT_DROPDOWN_VALUES[activeFilterMenu].map(it => (
                <li className={`product-orders__dropdown-item ${filterValue[activeFilterMenu] === it.value?'product-orders__dropdown-item_active':''}`}
                    key={it.title} onClick={(e) => handleChangeFilterValue(e, activeFilterMenu, it.value)}>
                    {it.title}
                    {filterValue[activeFilterMenu] === it.value?<CheckIcon nameOfClass="product-orders__dropdown-icon"/>:null}
                </li>
            ))}
        </ul>
    </div>
);

const createProductInOrderListKey = (product) => {
    return product.productId + product.productPrice + product.productCount + product.dateAdded;
};

const ProductsInOrderList = ({it}) => {
    try {
        return (
            it.products.map(product => {
                return (
                    <div key={createProductInOrderListKey(product)} className='table__item-product'>
                        {product.productImage?<img src={`${ELSI_STATIC_URL}/${product.productId}/${product.productImage}`} alt={product.productImage} className="table__item-image" />:''}
                        <p className={product.productImage&&'table__item-description'}>{product.productId}</p>
                        <p className={product.productImage&&'table__item-description'}>{product.productName}</p>
                        <p className={product.productImage&&'table__item-description'}>Цена товара: {product.productPrice}</p>
                        <p>Минимальное количество по этой цене: {product.productPriceQty}</p>
                        <p>Количество товара в заказе: {product.productCount}</p>
                    </div>
                );
            })
        );
    } catch(error) {
        console.log(error);
        return null;
    }
};

const OrdersList = ({listOfOrders, handleAddProductInOrder}) => {
    try {
        return (
            listOfOrders.activeList.map((it, index) => {
                return (
                    <tr key={it.id} className="table-row product-orders__table-row">
                        <td className={`table__item ${it.id ? 'table__item_active' : ''}`}>{it.orderNumber}</td>
                        <td className={`table__item ${it.totalCost ? 'table__item_active' : ''}`}>{it.totalCost?it.totalCost:0}</td>
                        <td className={`table__item ${it.dateCreation ? 'table__item_active' : ''}`}>{handleTransformDate(it.dateCreation)}</td>
                        {it.products && it.products.length ?
                            <td className={`table__item ${it.products && it.products.length ? 'table__item_active' : ''}`}>
                                <ProductsInOrderList it={it}/>
                            </td> : <td className="table__item"> - </td>}
                        <td className="table__item"><button className="button button_outline product-orders__table-button" onClick={() => handleAddProductInOrder(it.id)}>Добавить продукт</button></td>
                    </tr> 
                );
            })
        );
    } catch (error) {
        console.log(error);
        return null;
    }
};


const GetPricePopUp = () => {
    const [modalIsOpen , setModalIsOpen] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [listOfOrders, setListOfOrders] = useState({fullList: [], activeList: []});
    const divRef = useRef(null);
    const [customersList, setCustomersList] = useState([]);
    const [error, setError] = useState({type:'', message: ''});
    const [activeFilterMenu, setActiveFilterMenu] = useState(null);
    const [filterValue, setFilterValue] = useState({search: '', from: '', to:'', date: null, partnumber: null});
    const [ canUserGetPrice, product ] = useContext(ProductContext);

    const getListOfOrdersSuccessCallback = (response) => {
        const preparedData = response.map(it => ({ ...it, dateInMs: transformToMs(it.dateCreation)}));
        setListOfOrders({fullList: preparedData, activeList: preparedData});
    };

    const succesCallback = async (response) => {
        const result = await response;
        handleGetEmail(result.id);
        setUserId(result.id);
    };

    const makeRequestSuccessCallback = (response) => {
        toast('Запрос отправлен', {autoClose:5000, 
            type: 'success', 
            closeOnClick: true,
            position:'top-left',
            theme:"light"});
    };

    const succesGetEmailCallback = (response) => {
        setUserEmail(response.email);
    };

    const addNewItemCallback = async (response) => {
        const result = await response.json();
        result.dateInMs = transformToMs(result.dateCreation);
        listOfOrders.fullList.push(result);
        setListOfOrders({...listOfOrders, fullList: listOfOrders.fullList});
        divRef.current = document.getElementsByClassName('product-orders__table-wrapper')[0];
    };

    const successCallbackAddNewProduct = (response) => {
        if(response) {
            getFetchRequest(makeUrl([MAKE_ORDER_REQUEST, '?status=CREATED']), getListOfOrdersSuccessCallback, errorCallback);
        }
    };

    const successGetUserCallback = (response) => {
        if(response.id) {
            getFetchRequest(makeUrl([MAKE_USER_REQUEST, response.id, '/customer/']), (response) => setCustomersList(response), errorCallback);
        }
    };

    useEffect(() => {
        if(modalIsOpen) {
            if (!canUserGetPrice) {
                localStorage.getItem('token')&&getFetchRequest(makeUrl([GET_CURRENT_USER]), succesCallback, errorCallback);
            } else {
                getFetchRequest(makeUrl([MAKE_ORDER_REQUEST, '?status=CREATED']), getListOfOrdersSuccessCallback, errorCallback);
                getFetchRequest(makeUrl([GET_CURRENT_USER]), successGetUserCallback, errorCallback);
            }
        }
    }, [modalIsOpen]);

    const changePopupVisibility = () => {
        setModalIsOpen(true);
    };

    useEffect(() => {
        popupOpenService.registerPopup(ADD_PRODUCT_TO_ORDER_POPUP_KEY, changePopupVisibility);
        popupOpenService.registerPopup(SEND_PRICES_REQUEST, changePopupVisibility);
        return () => {
            popupOpenService.unRegisterPopup(ADD_PRODUCT_TO_ORDER_POPUP_KEY, changePopupVisibility);
            popupOpenService.unRegisterPopup(SEND_PRICES_REQUEST, changePopupVisibility);
        };
    }, []);

    useEffect(() => {
        if(listOfOrders.fullList&&listOfOrders.fullList.length&&divRef&&divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    },[listOfOrders]);

    const handleGetEmail = (id) => {
        getFetchRequest(makeUrl([MAKE_USER_REQUEST, id, '/']), succesGetEmailCallback, errorCallback);
    };

    const sendRequest = () => {
        if (canUserGetPrice) {
            if(customersList.length){
                postFetchRequest(makeUrl([MAKE_ORDER_REQUEST]), null, addNewItemCallback, errorCallback);
            } else {
                setError({type: 'customer', message: 'Покупатель не найден'});
            }
        } else {
            const data = {
                userId: userId,
                userEmail: userEmail,
                requestType: "PRICE",
                requestProductId: product.id,
            };
            if(userId) {
                data.userId = userId;
            } else {
                data.userId = null;
            }
            postFetchRequest(makeUrl([MAKE_REQUEST]), data, makeRequestSuccessCallback, errorCallback);
            setModalIsOpen(false);
        }
    };

    const handleAddProductInOrder = (orderId) => {
        const currentPrices = Object.values(cartManager.getCurrentPrices());
        for(let price of currentPrices) {
            if(price.count > 0) {
                const currentPriceValue = price.quantities.sort(sortByQuantity).findLast(it => it.minQty <= price.count);
                const data = {
                    "productId": product.id,
                    "productPriceId": currentPriceValue.id,
                    "productCount": price.count,
                };
                postFetchRequest(makeUrl([MAKE_ORDER_REQUEST, orderId, '/product/']), data, successCallbackAddNewProduct, errorCallback, true);
            }
        }
    };

    const handleChangeFilterValue = (e, key=null, value=null) => {
        e.stopPropagation();
        if(!key) {
            filterValue.search = e.target.value;
        } else if(value) {
            filterValue[key] = value;
        } else {
            filterValue[key] = e.target.value;
        }
        setFilterValue({...filterValue});
    };

    const handleSetActiveMenu = (e, key) => {
        e.stopPropagation();
        e.preventDefault();
        setActiveFilterMenu(key);
    };

    useEffect(() => {
        if(listOfOrders.fullList && listOfOrders.fullList.length) {
            let preparedData = [...listOfOrders.fullList];
            if(filterValue.search) {
                preparedData = preparedData.filter(it => it.id.includes(filterValue.search));
            }
            if(filterValue.from && filterValue.from.length > 9) {
                preparedData = preparedData.filter(it => it.dateInMs>transformToMs(filterValue.from));
            }
            if(filterValue.to && filterValue.to.length > 9) {
                preparedData = preparedData.filter(it => it.dateInMs<transformToMs(filterValue.to));
            }
            if(filterValue.partnumber) {
                preparedData = filterValue.partnumber === FILTER_DIRECTION_UP ?
                    preparedData.sort((a,b) => b.id.localeCompare(a.id)):
                    preparedData.sort((a,b) => a.id.localeCompare(b.id));
            }
            if(filterValue.date) {
                preparedData = filterValue.date === FILTER_DIRECTION_UP ?
                    preparedData.sort((a,b) => b.dateInMs - a.dateInMs):
                    preparedData.sort((a,b) => a.dateInMs - b.dateInMs);
            }
            setListOfOrders({...listOfOrders, activeList: preparedData});
        }
    }, [filterValue]);

    return (
        modalIsOpen ? 
            <>
                <div className="product-popup_background"></div>
                <div className={`product-popup ${canUserGetPrice ? '' : 'product-popup_small'}`} onClick={(e) => handleSetActiveMenu(e, null)}>
                    <CloseIcon handleClick={() => setModalIsOpen(false)}/>
                    {canUserGetPrice ?
                        <div className="product-orders__table-wrapper">
                            <table className="table product-orders__table">
                                <thead>
                                    <tr className="table-header">
                                        <th className="table-header__item_sticky product-orders__table-header" onClick={(e) => handleSetActiveMenu(e, FILTER_MENU_PARTNUMBER)}>
                                            Номер заказа 
                                            <ChevronIcon nameOfClass={`product-orders__table-icon ${activeFilterMenu===FILTER_MENU_PARTNUMBER?'product-orders__table-icon_active':''}`}/>
                                            {activeFilterMenu&&activeFilterMenu === FILTER_MENU_PARTNUMBER ?
                                                <DropdownListLayout activeFilterMenu={activeFilterMenu} filterValue={filterValue} handleChangeFilterValue={handleChangeFilterValue}/>
                                                :
                                                null}
                                        </th>
                                        <th className="table-header__item_sticky product-orders__table-header">Общая стоимость</th>
                                        <th className="table-header__item_sticky product-orders__table-header" onClick={(e) => handleSetActiveMenu(e, FILTER_MENU_DATE_CREATED)}>
                                            Дата создания
                                            <ChevronIcon nameOfClass={`product-orders__table-icon ${activeFilterMenu===FILTER_MENU_DATE_CREATED?'product-orders__table-icon_active':''}`}/>
                                            {activeFilterMenu&&activeFilterMenu === FILTER_MENU_DATE_CREATED ?
                                                <DropdownListLayout activeFilterMenu={activeFilterMenu} filterValue={filterValue} handleChangeFilterValue={handleChangeFilterValue}/>
                                                :
                                                null}
                                        </th>
                                        <th className="table-header__item_sticky product-orders__table-header">Продукты в заказе</th>
                                        <th className="table-header__item_sticky product-orders__table-header">Добавить продукт</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <OrdersList listOfOrders={listOfOrders} handleAddProductInOrder={handleAddProductInOrder}/>
                                </tbody>
                            </table>
                        </div>
                        :
                        <label className="product-popup__label">
                            <input className="product-popup__input" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                        </label>
                    }
                    {error.type==='customer'? <p className="product-popup__error">{error.message}</p>:''}
                    <div className="product-orders__filter-buttons">
                        {filterValue.search||filterValue.from||filterValue.to ? 
                            <CloseIconSmall nameOfClass="product-orders__filter-close"
                                handleClick={() => setFilterValue({...filterValue, search:'', from:'', to:''})}/>
                            :
                            null}
                        {activeFilterMenu !== FILTER_MENU_SEARCH ? 
                            <button className="button product-popup__button" type='button' onClick={sendRequest}>
                                {canUserGetPrice ? "создать новый заказ" : "отправить запрос"}
                            </button> 
                            : 
                            <div className="product-orders__filter">
                                <OrderSearchInput handleKeyDown={null} productSearch={filterValue.search} handleChangeSearchInput={handleChangeFilterValue} 
                                    listOfSearchOptions={[]} placeholder="Поиск по номеру заказа"/>
                                <div className="product-orders__filter-inputs">
                                    <span className="product-orders__filter-from">
                                        <input className="product-orders__filter-input" placeholder="2023.03.14" value={filterValue.from} 
                                            onChange={(e) => handleChangeFilterValue(e, 'from')} onClick={(e) => e.stopPropagation()}/>
                                    </span>
                                    <span className="product-orders__filter-to">
                                        <input className="product-orders__filter-input product-orders__filter-input_content" value={filterValue.to} placeholder="2023.03.14"
                                            onChange={(e) => handleChangeFilterValue(e, 'to')} onClick={(e) => e.stopPropagation()}/>
                                    </span>
                                </div>
                            </div>}
                        {canUserGetPrice ? 
                            <div className="product-orders__table-button-wrapper">
                                <button className={`button ${activeFilterMenu === FILTER_MENU_SEARCH?'product-orders__table-filter_outline':'button_outline'} product-orders__table-filter`} 
                                    onClick={(e) => handleSetActiveMenu(e, FILTER_MENU_SEARCH)}>Фильтровать</button>
                            </div>
                            :
                            null}
                    </div>
                </div>
                
            </>
            :
            null
    );
};

export default GetPricePopUp;
