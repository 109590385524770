import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { NavigationBlockContext } from "../const/const";
import { CustomReactRouter } from "../services/customRouter";

const WithWrapper = (Component, isCollapsed) => {
    const WrappedComponent = (props) => {
        const [currentPath, setCurrentPath] = useState('');
        const [categories, setCategories] = useState('');
        const [title, setTitle] = useState('');
        const router = useNavigate();
        const CustomRouter = new CustomReactRouter(router);
        const [listOfSearchOptions, setListOfSearchOptions] = useState([]);
        const ws = useRef(null);

        const cabinetProps = {...props};
        return (
            <div className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} catalog`}>
                <NavigationBlockContext.Provider value={[currentPath, setCurrentPath, categories, setCategories,title, setTitle, router, CustomRouter, listOfSearchOptions, setListOfSearchOptions, ws ]}>
                    <Component {...cabinetProps} />
                </NavigationBlockContext.Provider>
            </div>
        );
    };
    return WrappedComponent;
};
  
export default WithWrapper;