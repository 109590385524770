import React, { useEffect, useState } from 'react';
import { cartManager, showPricesWithSpaces } from './cartManager';
import { findOnePriceValue } from './CartTotalPrice';

const checkNotEmptyPrice = (price) => price.count !== 0;

const CartProductsList = () => {
    const [currentPrices, setCurrentPrices] = useState([]);

    const changeCurrentPrice = (pricesValue) => {
        setCurrentPrices([...pricesValue]);
    };

    useEffect(() => {
        cartManager.registerCartObserver(changeCurrentPrice);
        return () => cartManager.unRegisterCartObserver(changeCurrentPrice);
    }, []);

    return (
        <ol>
            <div className='product-price__cart-title'>
                <p>Количество</p>
                <p>Цена</p>
            </div>
            {currentPrices.map(it => (
                checkNotEmptyPrice(it) ?
                    <li className='product-price__cart-product' key={it.id}>
                        <p className='product-price__cart-count'>
                            <span>{it.count} шт.</span>
                            <span>Всего с НДС:</span>
                        </p>
                        <p className='product-price__cart-count'>
                            <span>{showPricesWithSpaces(findOnePriceValue(it).toFixed(2))} {it.currency === 'USD' ? '$':'₽'}</span>
                            <span>{showPricesWithSpaces((findOnePriceValue(it) * it.count).toFixed(2))} {it.currency === 'USD' ? '$':'₽'}</span>
                        </p>
                    </li>  
                    :
                    null
            ))}
        </ol>
    );
};

export default CartProductsList;
