import React, { useState, useEffect } from 'react';
import { CartIcon } from "../../appearance/icons/cart_icon";
import { cartManager } from './cartManager';
import { popupOpenService } from './popupOpenService';
import { ADD_PRODUCT_TO_ORDER_POPUP_KEY } from '../../const/const';
import { getAllPricesCount } from './CartTotalPrice';

const AddToOrderButton = () => {
    const [canUserOpenOrder, setCanUserOpenOrder] = useState(false);
    
    const checkEnoughCount = (pricesValue) => {
        setCanUserOpenOrder(cartManager.isCountEnough() && getAllPricesCount(pricesValue) > 0);
    };

    useEffect(() => {
        cartManager.registerCartObserver(checkEnoughCount);
        return () => cartManager.unRegisterCartObserver(checkEnoughCount);
    }, []);

    return (
        <button className={`button product-price__cart-button ${canUserOpenOrder?'':'button_disabled'}`}
            type="button" disabled={!canUserOpenOrder} onClick={() => popupOpenService.callToOpen(ADD_PRODUCT_TO_ORDER_POPUP_KEY)}>
            В корзину
            <CartIcon nameOfClass={`icon product-price__cart-icon ${canUserOpenOrder?'':'product-price__cart-icon_disabled'}`}/>
        </button>
    );
};

export default AddToOrderButton;
