import React from "react";
import HintsList from "./HintsList";
import SearchInput from "./SearchInput";

export function SearchHandler({CustomRouter}) {
    return (
        <>
            <SearchInput CustomRouter={CustomRouter}/>
            <HintsList CustomRouter={CustomRouter}/>
        </>
    );
};
